import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function FlowTitle({ children, className }) {
  return (
    <div
      className={cx(
        'flow-title-bar bg-dark-purple text-white text-center p-3 w-100 flex-grow-0',
        className
      )}
    >
      {children}
    </div>
  );
}

FlowTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FlowTitle.defaultProps = {
  className: '',
};

export default FlowTitle;
