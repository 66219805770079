import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css/animate.min.css';
import './index.css';
import './objects.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import ReactGA from 'react-ga';
import RouteChangeTracker from './components/RouteChangeTracker';

const TRACKING_ID = 'UA-190146292-1';
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RouteChangeTracker />
      <Route path="/">
        <Suspense fallback={<h1>LOADING</h1>}>
          <App
            config={{
              lambdaUrl: process.env.REACT_APP_LAMBDA_URL,
            }}
          />
        </Suspense>
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
