import { FLOW_ITEM_TYPES, FLOW_MEDIA_TYPES } from './constants';
import config from './items/inszenierteStadt';

const { config10Min: inszenierteStadt } = config;

export default [
  {
    id: 'ROT_BLAU',
    title: 'pages.flowSelection.rotblau',
    stopMotion: 'https://d1y6be8ryibzao.cloudfront.net/videos/rotblau-stopmotion.webm',
    loadingTitleKey: 'loadingText.rotblau.title', 
    loadingTextKey: 'loadingText.rotblau.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.rotblau',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/rotblau-I-ver2.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.themaVariation',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/thema-variation-ver3.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.rotblau',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/rotblau-II.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.clappingWorkshop',
        promptRange: [(60 * 2 + 40) * 10000, (60 * 4 + 30) * 10000],
        promptUrl: 'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music-workshop-exit.webm',
        clapping: true, 
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.clappingWorkshop',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music-workshop.webm',
          },
        ],
      },
    ],
  },
  {
    id: '1234',
    title: 'pages.flowSelection.1-2-3-4',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/1-2-3-4-stopmotion.webm',
    loadingTitleKey: 'loadingText.1-2-3-4.title',
    loadingTextKey: 'loadingText.1-2-3-4.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.hommage',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/hommage.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.counting',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            multilingual: true,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/counting-duets-de.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.trio',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            multilingual: true,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/trio-de.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.comeAgain',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/come-again.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'sinfonie_einer_grossstadt',
    title: 'pages.flowSelection.sinfonieEinerGroßstadt',
    stopMotion: 'https://d1y6be8ryibzao.cloudfront.net/videos/sinfonie-stopmotion.webm',
    loadingTitleKey: 'loadingText.sinfonie-einer-großstadt.title', 
    loadingTextKey: 'loadingText.sinfonie-einer-großstadt.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.WHERE_NEXT,
        title: 'Where do you want to go next?',
        media: [],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: '', // Title is set based on the media selection
        id: 'whereNextResult',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.staringAtTheBin',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/staring-at-the-bin.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.statues',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/statues.webm',
          },
        ],
      },
      inszenierteStadt,
    ],
  },
];
