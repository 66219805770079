import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useMedia from '../hooks/useMedia';
import LanguageSelector from '../components/LanguageSelector';
import LogoSquare from '../components/LogoSquare';
import FlowPromptModal from '../components/FlowPromptModal';
import FlowSelectionMenu from '../components/FlowSelectionMenu';
import useCalculateLayout from '../hooks/useCalculateLayout';
import cx from 'classnames';

function getInitialPromptState() {
  return {
    isOpen: false,
    title: '',
    confirmLink: '',
    message: '',
    cancelText: '',
    isCancelShowing: false,
    confirmText: '',
  };
}

const DEFAULT_STOP_MOTION_URL =
  'https://d1y6be8ryibzao.cloudfront.net/videos/applause-stopmotion.webm';

function FlowSelectionPage(props) {
  const { getExperience, setHidePdfScore } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { userTime } = useParams();
  const experience = getExperience(userTime);

  const [promptModalState, setPromptModalState] = useState(
    getInitialPromptState()
  );
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [stopMotionUrl, setStopMotionUrl] = useState(DEFAULT_STOP_MOTION_URL);

  const onStopMotionEnd = useCallback(() => navigateToFlow(selectedFlow.id), [
    selectedFlow,
  ]);

  const { mediaRef: videoRef } = useMedia({
    onEnd: onStopMotionEnd,
    src: DEFAULT_STOP_MOTION_URL,
    autoPlay: false,
  });

  const {
    mediaRef: stopMotionRef,
    togglePlay: toggleStopMotion,
    time,
  } = useMedia({
    onEnd: onStopMotionEnd,
    src: stopMotionUrl,
    autoPlay: false,
  });

  const { calculateVideoSizeForAspectRatio } = useCalculateLayout();

  function closePromptModal() {
    setPromptModalState(getInitialPromptState());
  }

  function openPromptModalForFlow(flow) {
    const printerRequiredModalConfig = {
      confirmText: t('manuskriptPrompt.confirmButton'),
      cancelText: t('manuskriptPrompt.cancelButton'),
      isCancelShowing: true,
      message: t('manuskriptPrompt.promptQuestion'),
    };

    const headpgonesRequiredModalConfig = {
      confirmText: t('pitchPrompt.confirmButton'),
      isCancelShowing: false,
      message: t('pitchPrompt.text'),
    };

    const promptConfig = flow.requiredPrinter
      ? printerRequiredModalConfig
      : headpgonesRequiredModalConfig;

    setPromptModalState({
      isOpen: true,
      title: t(flow.title),
      onConfirm: () => {
        setSelectedFlow(flow);
        setPromptModalState(getInitialPromptState());
      },
      onCancel: () => {
        setHidePdfScore(true);
        setSelectedFlow(flow);
        setPromptModalState(getInitialPromptState());
      },
      ...promptConfig,
    });
  }

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
  };

  function navigateToFlow(flowId) {
    history.push(`/experience/${userTime}/${flowId}/0`);
  }

  function onFlowSelect(evt, flow) {
    evt.preventDefault();
    setStopMotionUrl(flow.stopMotion);
    if (flow.requiredPrinter || flow.requiresHeadphones) {
      openPromptModalForFlow(flow);
    } else {
      setSelectedFlow(flow);
    }
  }

  useEffect(() => {
    if (selectedFlow) {
      toggleStopMotion();
    }
  }, [selectedFlow]);

  let isVideoSmall = false;
  if (videoRef.current) {
    const ratio = 1.6;
    const size = videoRef.current.getBoundingClientRect();
    const { videoHeight } = calculateVideoSizeForAspectRatio({ ratio, size });
    if (videoHeight < 900) {
      isVideoSmall = true;
    }
  }

  const flexCenterVerticalClassNames =
    'd-flex justify-content-center flex-column align-items-center';

  return (
    <div
      className={cx(
        'h-100 bg-radial-gradient position-relative',
        flexCenterVerticalClassNames
      )}
    >
      <div
        className={cx(
          'position-relative mx-auto',
          flexCenterVerticalClassNames
        )}
      >
        <video
          ref={stopMotionRef}
          key={stopMotionUrl}
          className="h-100 w-100 bg-transparent position-absolute"
        >
          <source type="video/webm" src={stopMotionUrl} />
        </video>
        <video
          ref={videoRef}
          className="h-100 w-100 bg-transparent"
          onCanPlay={() => setIsVideoLoaded(true)}
          style={{
            opacity: time > 0 ? 0 : 1,
          }}
        >
          <source type="video/webm" src={DEFAULT_STOP_MOTION_URL} />
        </video>
        {isVideoLoaded && (
          <FlowSelectionMenu
            onFlowSelect={onFlowSelect}
            flows={experience.flows}
            userTime={userTime}
            isVideoSmall={isVideoSmall}
            selectedFlow={selectedFlow}
          />
        )}
      </div>
      <Link to="/">
        <LogoSquare />
      </Link>
      <LanguageSelector
        currentLanguage={i18n.language}
        onLanguageSelect={changeLanguage}
        isTextDark
      />
      <FlowPromptModal {...promptModalState} onClose={closePromptModal} />
      {window.screen.availWidth < 898 && (
        <div className="landscape-warning bg-dark-purple  font-weight-bold">
          <img src="/landscape-indicator.gif" className="img-fluid" />
        </div>
      )}
    </div>
  );
}

export default FlowSelectionPage;
