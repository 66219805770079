function useCalculateLayout() {
  /**
   * check ratio of current window size
   * if it's wider than 16 / 9, use height as anchor (we will have horizontal gutters)
   * find target width by multiplying height by 16/9
   * if it's taller than 16 / 9, use width as anchor (we will have vertical gutters)
   * find target height by dividing width by 16/9
   * find scale by dividing original width (1440) with window width
   */
  const calculateASMRLayout = ({ windowSize, mobile }) => {
    const { width, height } = windowSize;
    let targetWidth = width || 1;
    let targetHeight = height || 1;
    const targetRatio = 1440 / 900;
    const windowRatio = width / height;

    if (windowRatio >= targetRatio) {
      targetWidth = targetHeight * targetRatio;
    } else {
      targetHeight = targetWidth / targetRatio;
    }

    // this is based on Tero's design
    const realWidth = 1326;

    const scale = (targetWidth / 1440) * (mobile ? 0.5 : 1);
    const leftMargin = Math.max((width - realWidth) / 2, 0);
    const topMargin = Math.max((height - 900) / 2, 0);
    return { scale, leftMargin, topMargin };
  };

  const calculateVideoSizeForAspectRatio = ({ ratio, size }) => {
    const { width, height } = size;
    let videoHeight;
    let videoWidth;
    if (height > width / ratio) {
      videoHeight = width / ratio;
      videoWidth = width;
    } else if (width > height * ratio) {
      videoHeight = height;
      videoWidth = height * ratio;
    } else {
      videoHeight = height;
      videoWidth = width;
    }

    return { videoHeight, videoWidth };
  };

  const calculateFramePositionForDrawingVideo = ({ size }) => {
    const ratio = 16 / 9;
    const { width, height } = size;

    const { videoHeight, videoWidth } = calculateVideoSizeForAspectRatio({
      ratio,
      size,
    });
    const scale = videoWidth / 1920;

    /*
    if the screen is higher than width / ratio, then we have vertical gutters
    if the screen is wider than height * ratio, then we have horizontal gutters    
    */
    const topGutter = (height - videoHeight) / 2;
    const rightGutter = (width - videoWidth) / 2;

    const canvasWidth = 403 * scale;
    const canvasHeight = 293 * scale;
    const frameTop = 52 * scale;
    const frameRight = 60 * scale;

    return {
      top: `${topGutter + frameTop}px`,
      right: `${rightGutter + frameRight}px`,
      width: `${canvasWidth}px`,
      height: `${canvasHeight}px`,
    };
  };

  return {
    calculateASMRLayout,
    calculateVideoSizeForAspectRatio,
    calculateFramePositionForDrawingVideo,
  };
}

export default useCalculateLayout;
