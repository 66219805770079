import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

function LogoType({ small, className }) {
  return (
    <h1
      className={cx(
        'logo-type text-center',
        {
          'logo-type--small': small,
        },
        className
      )}
    >
      SOUND_<span className="font-weight-600">TRACKS</span>
    </h1>
  );
}

LogoType.propTypes = {
  small: PropTypes.bool,
  className: PropTypes.string,
};

LogoType.defaultProps = {
  small: false,
  className: '',
};

export default LogoType;
