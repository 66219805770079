import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import OddModal from './Modal';

function FlowPromptModal({
  isOpen,
  title,
  isCancelShowing,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  message,
  onCancel,
}) {
  return (
    <OddModal isOpen={isOpen} onClose={onClose}>
      <div className="text-center">
        <h3 className="prompt-modal__title font-weight-600">{title}</h3>
        <p className="prompt-modal__message font-weight-600">{message}</p>
        <div className="d-flex justify-content-center align-items-center">
          {isCancelShowing && (
            <Button onClick={onCancel} type="danger" className="mr-3">
              {cancelText}
            </Button>
          )}
          <Button className="btn btn-success" onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </div>
    </OddModal>
  );
}

FlowPromptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isCancelShowing: PropTypes.bool.isRequired,
  cancelText: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default FlowPromptModal;
