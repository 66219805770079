import React from 'react';

function LogoSquare() {
  return (
    <img
      src="/logo.png"
      alt="DieOrdnungDerDinge"
      className="position-absolute"
      style={{ left: '41px', top: '29px' }}
    />
  );
}

export default LogoSquare;
