import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

function Button({ className, type, onClick, children, disabled }) {
  const typeClass = `btn-${type}`;
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cx('btn', typeClass, className)}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'default',
    'danger',
    'success',
    'outline-white',
    'outline-black',
    'default-no-border',
  ]),
};

Button.defaultProps = {
  className: '',
  type: 'default',
  onClick: noop,
  disabled: false,
};

export default Button;
