import { useEffect, useState } from 'react';
import p5 from 'p5';
import data from '../components/dndData';
import Rectangle from '../components/Rectangle';

const { objects, photoFrame } = data;

function useP5({
  ref,
  onDrag,
  w,
  h,
  frameH,
  frameW,
  frameX,
  frameY,
  scale,
  isReady,
}) {
  const [sketch, setSketch] = useState(null);
  useEffect(() => {
    if (!isReady) {
      return;
    }
    const newSketch = new p5(Sketch, ref.current);
    setSketch(newSketch);
  }, [isReady]);

  const Sketch = (p) => {
    let rects = [];
    let images = [];
    let dragRec;
    let isDragging;
    let clickOffset;
    let frame;

    const placeImages = () => {
      const topMargin = (h - frameH) / 2;
      // each object is placed relative to the position of the picture frame
      objects.forEach((object, index) => {
        images.push(
          p.loadImage(object.url, () => {
            // We either stick to the original frame size and object distribution, or we're scaling it down for smaller screens.
            // Some distances from the frame are negative (to the left, or above the frame).
            // therefore we need to check for absolute magnitude and choose the smaller value when scaling.
            // After we've chosen the correct magnitude, we need to reassign the sign of the value.
            const signOfX = object.x < 0 ? -1 : 1;
            const signOfY = object.y < 0 ? -1 : 1;
            const scaledAbsX = Math.min(
              Math.abs(object.x),
              Math.abs(object.x) * scale
            );
            const scaledAbsY = Math.min(
              Math.abs(object.y),
              Math.abs(object.y) * scale
            );
            const x = scaledAbsX * signOfX;
            const y = scaledAbsY * signOfY;
            let pos = p.createVector(frameX + x, frameY + y);
            rects.push(new Rectangle(p, pos, images[index], scale));
          })
        );
      });
    };

    const setupFrame = () => {
      const loadedFramePic = p.loadImage(photoFrame, () => {
        // const width = frameW;
        // const height = frameH;
        const x = frameX;
        const y = frameY;
        const framePos = p.createVector(x, y);
        frame = new Rectangle(p, framePos, loadedFramePic, scale);
      });
    };

    p.preload = () => {
      setupFrame();
    };

    p.setup = () => {
      rects = [];
      placeImages();
      isDragging = false;
      p.pixelDensity(1);

      p.createCanvas(w, h);
    };

    p.draw = () => {
      p.clear();
      if (frame) {
        frame.draw();
      }
      rects.forEach((r) => r.draw());
    };

    p.mousePressed = () => {
      let m = p.createVector(p.mouseX, p.mouseY);
      let index;
      rects.forEach((rect, i) => {
        if (rect.hits(m)) {
          clickOffset = p5.Vector.sub(rect.pos, m);
          isDragging = true;
          dragRec = rect;
          index = i;
        }
      });
      if (isDragging) {
        putOnTop(index);
      }
    };

    const putOnTop = (index) => {
      rects.splice(index, 1);
      rects.push(dragRec);
    };

    p.mouseDragged = () => {
      if (isDragging) {
        let m = p.createVector(p.mouseX, p.mouseY);
        dragRec.pos.set(m).add(clickOffset);
        onDrag();
      }
    };

    p.mouseReleased = () => {
      isDragging = false;
    };
  };

  return {
    sketch,
  };
}

export default useP5;
