import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FLOW_ITEM_TYPES } from '../config/constants';
import VideoSingle from '../components/items/VideoSingle';
import AudioSingle from '../components/items/AudioSingle';
import WhereNext from '../components/items/WhereNext';
import ASMR from '../components/items/ASMR';
import PdfScore from '../components/items/PdfScore';
import ChooseMusic from '../components/items/ChooseMusic';
import Drawing from '../components/items/Drawing';
import EnsemblePoll from '../components/items/EnsemblePoll';
import WhoShouldTellYou from '../components/items/WhoShouldTellYou';
import YesNoVideo from '../components/items/YesNoVideo';
import OddModal from '../components/Modal';
import VideoDrawing from '../components/items/VideoDrawing';
import EnsemblePollResults from '../components/items/EnsemblePollResults';
import EnsemblePollIntro from '../components/items/EnsemblePollIntro';
import { DrawingContext } from '../DrawingContext';
import VideoStadt from '../components/items/VideoStadt';
import useTimeout from '../hooks/useTimeout';
import LoadingPage from './LoadingPage';
import VideoWithPrompt from '../components/items/VideoWithPrompt';

const flowTypeToComponentMap = {
  [FLOW_ITEM_TYPES.VIDEO_SINGLE]: VideoSingle,
  [FLOW_ITEM_TYPES.VIDEO_STADT]: VideoStadt,
  [FLOW_ITEM_TYPES.VIDEO_DRAWING]: VideoDrawing,
  [FLOW_ITEM_TYPES.WHERE_NEXT]: WhereNext,
  [FLOW_ITEM_TYPES.ASMR]: ASMR,
  [FLOW_ITEM_TYPES.PDF_SCORE]: PdfScore,
  [FLOW_ITEM_TYPES.CHOOSE_MUSIC]: ChooseMusic,
  [FLOW_ITEM_TYPES.DRAWING]: Drawing,
  [FLOW_ITEM_TYPES.ENSEMBLE_POLL]: EnsemblePoll,
  [FLOW_ITEM_TYPES.WHO_SHOULD_TELL_YOU]: WhoShouldTellYou,
  [FLOW_ITEM_TYPES.YES_NO_VIDEO]: YesNoVideo,
  [FLOW_ITEM_TYPES.AUDIO]: AudioSingle,
  [FLOW_ITEM_TYPES.ENSEMBLE_POLL_RESULTS]: EnsemblePollResults,
  [FLOW_ITEM_TYPES.ENSEMBLE_POLL_INTRO]: EnsemblePollIntro,
};

function FlowEndModal({ t, isOpen, flowTitle, onClose }) {
  return (
    <OddModal isOpen={isOpen} onClose={onClose}>
      <div className="text-center py-4">
        <h3 className="prompt-modal__title font-weight-600">
          {t('stopFlowPrompt.title')}
        </h3>
        <p className="prompt-modal__message font-weight-600 mb-4">
          {t('stopFlowPrompt.subtitle', { title: t(flowTitle) })}
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <button onClick={onClose} className="btn btn-default mr-3">
            {t('stopFlowPrompt.cancelButton')}
          </button>
          <Link className="btn btn-success" to="/">
            {t('stopFlowPrompt.confirmButton')}
          </Link>
        </div>
      </div>
    </OddModal>
  );
}

function Flow(props) {
  const { getFlow } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isFlowEndModalShowing, setIsFlowEndModalShowing] = useState(false);
  const { flowId, itemIndex, userTime } = useParams();
  const index = parseInt(itemIndex, 10);
  const flow = getFlow(userTime, flowId);
  const [flowState, setFlowState] = useState({});
  const [isLoadingScreenShowing, setIsLoadingScreenShowing] = useState(true);
  const [isLoadingScreenFadingOut, setIsLoadingScreenFadingOut] = useState(
    false
  );
  const [
    shouldShowLoadingPageNextButton,
    setShouldShowLoadingPageNextButton,
  ] = useState(false);

  useTimeout({
    onTimeout: () => setShouldShowLoadingPageNextButton(true),
    condition: isLoadingScreenShowing,
    duration: 4000,
  });

  const setStateForStep = useCallback(
    (statePairs) => {
      const newState = statePairs.reduce((acc, statePair) => {
        const { stepId, state } = statePair;
        acc[stepId] = state;
        return acc;
      }, {});

      setFlowState({
        ...flowState,
        ...newState,
      });
    },
    [flowState]
  );

  const currentItem = flow.items[index];
  const openFlowEndModal = () => setIsFlowEndModalShowing(true);
  const closeFlowEndModal = () => setIsFlowEndModalShowing(false);

  const nextitemIndex = index + 1;
  const previtemIndex = index - 1;
  const nextItem = flow.items[nextitemIndex];
  // const prevItem = flow.items[previtemIndex];

  const hasNext = !!nextItem;
  // const hasPrev = !!prevItem;

  const stepState = flowState[currentItem.id] || {};
  const isPromptActive =
    currentItem.clapping || get(stepState, 'isPromptActive', false);
  const FlowItemComponent = isPromptActive
    ? VideoWithPrompt
    : flowTypeToComponentMap[currentItem.type];

  function onNextClick() {
    if (!hasNext) {
      return history.push(`/experience/${userTime}/${flowId}/feedback`);
    }

    return history.push(`/experience/${userTime}/${flowId}/${nextitemIndex}`);
  }

  function onPrevClick() {
    return history.push(`/experience/${userTime}/${flowId}/${previtemIndex}`);
  }

  const showPrevButton = previtemIndex > -1;

  if (isLoadingScreenShowing) {
    const title = t(flow.loadingTitleKey);
    const text = t(flow.loadingTextKey);

    if (title === '' && text === '') {
      setIsLoadingScreenShowing(false);
      setIsLoadingScreenFadingOut(true);
    } else {
      return (
        <LoadingPage
          isFadingOut={isLoadingScreenFadingOut}
          title={t(flow.loadingTitleKey)}
          text={t(flow.loadingTextKey)}
          shouldShowNextButton={shouldShowLoadingPageNextButton}
          onNextClick={() => {
            setIsLoadingScreenShowing(false);
            setIsLoadingScreenFadingOut(true);
          }}
        />
      );
    }
  }

  function getLanguage() {
    return i18n.language.split('-')[0] === 'de' ? 'de' : 'en';
  }

  return (
    <>
      <DrawingContext.Consumer>
        {({ drawingUrl, setDrawingUrl }) => (
          <FlowItemComponent
            item={currentItem}
            onNextClick={onNextClick}
            language={getLanguage()}
            showPrevButton={showPrevButton}
            onPrevClick={onPrevClick}
            onEndClick={openFlowEndModal}
            flowState={flowState}
            setStateForStep={setStateForStep}
            nextPath={`/experience/${userTime}/${flowId}/${nextitemIndex}`}
            prevPath={`/experience/${userTime}/${flowId}/${previtemIndex}`}
            drawingUrl={drawingUrl}
          />
        )}
      </DrawingContext.Consumer>
      <FlowEndModal
        t={t}
        isOpen={isFlowEndModalShowing}
        onClose={closeFlowEndModal}
        flowTitle={flow.title}
      />
    </>
  );
}

export default Flow;
