import { FLOW_ITEM_TYPES, FLOW_MEDIA_TYPES } from './constants';
import config from './items/inszenierteStadt';

const { config20Min: inszenierteStadt } = config;

const pollQuestions = [
  { title: 'question1', answers: [0, 1, 3] },
  { title: 'question2', answers: [] },
  { title: 'question3', answers: [0] },
  { title: 'question4', answers: [3] },
  { title: 'question5', answers: [1] },
  { title: 'question6', answers: [0] },
  { title: 'question7', answers: [3] },
  { title: 'question8', answers: [2] },
  { title: 'question9', answers: [1] },
  { title: 'question10', answers: [2] },
  { title: 'question11', answers: [1] },
  { title: 'question12', answers: [2] },
  { title: 'question13', answers: [0] },
  { title: 'question14', answers: [0] },
];

function createChooseMusicStep(id) {
  return {
    type: FLOW_ITEM_TYPES.CHOOSE_MUSIC,
    title: 'choose music (4 choices)',
    id,
    media: [
      {
        type: FLOW_MEDIA_TYPES.IMAGE,
        title: 'tracks.rotblau',
        url: '/choose-music-rotblau.png',
      },
      {
        type: FLOW_MEDIA_TYPES.IMAGE,
        title: 'tracks.elOrdenDeLasCosas',
        url: '/choose-music-el-orden.png',
      },
      {
        type: FLOW_MEDIA_TYPES.IMAGE,
        title: 'tracks.tableMusic',
        url: '/choose-music-table-music.png',
      },
      {
        type: FLOW_MEDIA_TYPES.IMAGE,
        title: 'tracks.silenceMustBe',
        url: '/choose-music-silence-must-be.png',
      },
      {
        type: FLOW_MEDIA_TYPES.IMAGE,
        title: 'tracks.soundwalk',
        url: '/headphones.png',
      },
    ],
  };
}

export default [
  {
    id: 'strukturen',
    title: 'pages.flowSelection.strukturen',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/strukturen-stopmotion.webm',
    loadingTitleKey: 'loadingText.strukturen.title',
    loadingTextKey: 'loadingText.strukturen.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.trio',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/trio-structures.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.indigenousOrders',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/indigenous-orders-structures.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.writingPiece',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/mobile-stationary-structures.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.ENSEMBLE_POLL_INTRO,
      },
      {
        type: FLOW_ITEM_TYPES.ENSEMBLE_POLL,
        title: 'Ensemble poll',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Iñigo',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/inigo-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Cathrin',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/cathrin-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Meriel',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/meriel-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Vera',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/vera-looped.webm',
          },
        ],
        questions: pollQuestions,
      },
      {
        type: FLOW_ITEM_TYPES.ENSEMBLE_POLL_RESULTS,
        title: 'Ensemble poll',
        id: 'ensemblePollResults',
        media: [],
      },
      inszenierteStadt,
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.macgurkEffect',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/mcgurk.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.comeAgain',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/come-again.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'MADE_FOR_YOU',
    title: 'pages.flowSelection.madeForYou',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/made-for-you-stopmotion.webm',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'Short personal intro',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/short-personal-intro.webm',
          },
        ],
      },
      createChooseMusicStep('madeForYouChooseMusic'),
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'Music choice',
        id: 'musicChoice',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.rotblau',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/rotblau-I-ver2.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.elOrdenDeLasCosas',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/el-orden-de-las-cosas.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.tableMusic',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/musiquedetables-de.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.silenceMustBe',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/silence-must-be_v2.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.DRAWING,
        title: 'Interacting page',
        media: [],
      },
      {
        type: FLOW_ITEM_TYPES.ENSEMBLE_POLL_INTRO,
      },
      {
        type: FLOW_ITEM_TYPES.ENSEMBLE_POLL,
        title: 'Ensemble poll',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Iñigo',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/inigo-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Cathrin',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/cathrin-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Meriel',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/meriel-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Vera',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/vera-looped.webm',
          },
        ],
        questions: pollQuestions,
      },
      {
        type: FLOW_ITEM_TYPES.ENSEMBLE_POLL_RESULTS,
        title: 'Ensemble poll',
        id: 'ensemblePollResults',
        media: [],
      },
      {
        type: FLOW_ITEM_TYPES.WHO_SHOULD_TELL_YOU,
        title: 'Who should now tell u about X',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Iñigo',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/inigo-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Cathrin',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/cathrin-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Meriel',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/meriel-looped.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'Vera',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/vera-looped.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.bühnenerfahrung',
        id: 'telling',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/Buhnenerfahrung-Inigo.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/Buhnenerfahrung-Cathrin.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/Buhnenerfahrung-Meriel.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/Buhnenerfahrung-Vera.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.YES_NO_VIDEO,
        title: 'pages.yesNoStep.title',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/listening-ensemble.webm',
          },
        ],
      },
      createChooseMusicStep('chooseMusicWithSoundwalk'),
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'musicChoiceWithSoundwalk',
        id: 'musicChoiceWithSoundwalk',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.rotbalu',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/rotblau-I-ver2.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.elOrdenDeLasCosas',

            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/el-orden-de-las-cosas.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.tableMusic',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/musiquedetables-de.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.silenceMustBe',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/silence-must-be_v2.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.AUDIO,
            title: 'tracks.soundwalk',
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/soundwalk.mp3',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.letsTalkAboutYou',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/lets-talk-about-you.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_DRAWING,
        title: 'Comment on interactive input',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/lets-talk-about-you-panel.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'manuskript',
    title: 'pages.flowSelection.manuskript',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/manuskript-stopmotion.webm',
    loadingTitleKey: 'loadingText.manuskript.title',
    loadingTextKey: 'loadingText.manuskript.text',
    requiredPrinter: true,
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.tableMusic',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/musiquedetables-de.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.AUDIO,
        title: 'tracks.palmReadingWorkshop',
        media: [
          {
            type: FLOW_MEDIA_TYPES.AUDIO,
            multilingual: true,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/palmreading-de.mp3',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.CHOOSE_MUSIC,
        title: 'choose music (4 choices)',
        id: 'chooseClapping',
        media: [
          {
            type: FLOW_MEDIA_TYPES.IMAGE,
            title: 'tracks.clappingMusic',
            url: '/choose-music-clapping.png',
          },
          {
            type: FLOW_MEDIA_TYPES.IMAGE,
            title: 'tracks.clappingWorkshop',
            url: '/choose-music-clapping-workshop.png',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.clappingMusic',
        id: 'clappingChoice',
        promptRange: [(60 * 2 + 40) * 10000, (60 * 4 + 30) * 10000],
        promptUrl:
          'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music-workshop-exit.webm',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.clappingMusic',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music.webm',
          },
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.clappingWorkshop',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music-workshop.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.writingPiece',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/mobile-stationary.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.PDF_SCORE,
        title: 'tracks.pdfScore',
        media: [],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.asmr',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/ASMR.webm',
          },
        ],
      },
    ],
  },
];
