import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import keyBy from 'lodash/keyBy';
import experiences from './config/experiences';
import FlowSelectionPage from './pages/FlowSelectionPage';
import Home from './pages/Home';
import Flow from './pages/Flow';
import Feedback from './components/Feedback';
import Credits from './pages/Credits';
import { DrawingContext } from './DrawingContext';
import { FLOW_ITEM_TYPES } from './config/constants';
import LogoType from './components/LogoType';
import LanguageSelector from './components/LanguageSelector';

function getExperience(userTime) {
  const experiencesByUserTime = keyBy(experiences, 'userTime');
  const experience = experiencesByUserTime[userTime];
  return experience;
}

const createGetFlow = ({ hidePdfScore }) => (userTime, flowId) => {
  const experience = getExperience(userTime);
  const flowsById = keyBy(experience.flows, 'id');
  const flow = flowsById[flowId];
  if (hidePdfScore) {
    return {
      ...flow,
      items: flow.items.filter(
        (item) => item.type !== FLOW_ITEM_TYPES.PDF_SCORE
      ),
    };
  }

  return flow;
};

export default function App({ config }) {
  const { t, i18n } = useTranslation();
  const [drawingUrl, setDrawingUrl] = useState('');
  const [hidePdfScore, setHidePdfScore] = useState(false);
  const getFlow = createGetFlow({ hidePdfScore });
  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
  };

  return (
    <>
      <div className="d-none h-100 bg-cream">
        <div className="d-flex align-items-center flex-column justify-content-center p-5 h-100">
          <div className="mb-3">
            <LanguageSelector
              currentLanguage={i18n.language}
              onLanguageSelect={changeLanguage}
              isTextDark
            />
          </div>
          <div>
            <LogoType small className="mb-4" />
            <div className="col-10 offset-1">
              <Trans
                i18nKey="message-use-desktop-title"
                components={{ a: <a /> }}
              />
              <ol className="mt-2 ml-3 pl-0">
                <li className="mt-1">
                  {t('message-use-desktop-instruction-1')}
                </li>
                <li className="mt-1">
                  {t('message-use-desktop-instruction-2')}
                </li>
                <li className="mt-1">
                  <Trans
                    i18nKey="message-use-desktop-instruction-3"
                    components={{ a: <a /> }}
                  />
                </li>
                <li className="mt-1">
                  <Trans
                    i18nKey="message-use-desktop-instruction-4"
                    components={{ a: <a /> }}
                  />
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <DrawingContext.Provider value={{ drawingUrl, setDrawingUrl }}>
        <div className="h-100 position-relative overflow-hidden">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/credits/:flowId">
              <Credits />
            </Route>
            <Route exact path="/experience/:userTime/:flowId/feedback">
              <Feedback lambdaUrl={config.lambdaUrl} getFlow={getFlow} />
            </Route>

            <Route exact path="/experience/:userTime/:flowId/:itemIndex">
              <Flow getFlow={getFlow} />
            </Route>

            <Route exact path="/experience/:userTime">
              <FlowSelectionPage
                getExperience={getExperience}
                setHidePdfScore={setHidePdfScore}
              />
            </Route>
          </Switch>
        </div>
      </DrawingContext.Provider>
    </>
  );
}
