import React, { useRef, useState, useEffect, useCallback } from 'react';
import p5 from 'p5';
import { useTranslation } from 'react-i18next';
import Rectangle from '../Rectangle';
import Button from '../Button';
import { DrawingContext } from '../../DrawingContext';
import useWindowSize from '../../hooks/useWindowSize';
import FlowControlBarSingleNextButton from '../FlowControlBarSingleNextButton';

let myP5 = null;

function Drawing({ onNextClick }) {
  const { width, height, isReady } = useWindowSize();
  if (isReady && width > 898) {
    return <DrawingDesktop onNextClick={onNextClick} />;
  } else if (isReady) {
    return <DrawingMobile onNextClick={onNextClick} />;
  } else {
    return <div>LOADING</div>;
  }
}

function DrawingDesktop({ onNextClick }) {
  const [didPaint, didDidPaint] = useState(false);
  const onPaint = useCallback(() => {
    if (didPaint === false) {
      didDidPaint(true);
    }
  }, [didPaint]);

  const { t } = useTranslation();

  const processingRef = useRef();
  const frameX = 60;
  const frameY = 55;
  const frameW = 704 - frameX * 2;
  const frameH = 529 - frameY * 2;

  useEffect(() => {
    const Sketch = (p) => {
      let canvas;
      const framePos = p.createVector(0, 0);
      const loadedFramePic = p.loadImage('/drawing-frame.png');
      const frame = new Rectangle(p, framePos, loadedFramePic);
      p.pixelDensity(1);

      p.hide = () => {
        p.clear();
        // p.background();
      };

      p.setup = () => {
        canvas = p.createCanvas(704, 529);
        canvas.parent('sketch');
        // p.background();
      };

      p.draw = () => {
        frame.draw();
        if (p.mouseIsPressed) {
          if (
            p.mouseX > 50 &&
            p.mouseX < 704 &&
            p.mouseY > 0 &&
            p.mouseY < 479
          ) {
            onPaint();
          }

          p.stroke(0);
          p.strokeWeight(4);
          p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY);
        }
      };
    };

    myP5 = new p5(Sketch, processingRef.current);
  }, []);

  function clip(oldCanvas) {
    var newCanvas = document.createElement('canvas');
    newCanvas.width = frameW;
    newCanvas.height = frameH;
    var newContext = newCanvas.getContext('2d');
    newContext.drawImage(
      oldCanvas,
      frameX,
      frameY,
      frameW,
      frameH,
      0,
      0,
      frameW,
      frameH
    );
    return newCanvas.toDataURL();
  }

  const onFinished = async (setDrawingUrl) => {
    setDrawingUrl(clip(myP5.canvas));
    onNextClick();
  };

  const onReset = () => {
    myP5.hide();
  };

  return (
    <div className="h-100 position-relative bg-radial-gradient d-flex row  align-items-center">
      <div className="col-6 text-center align-items-center justify-content-center">
        <div className="col-9 offset-1 title">{t('pages.drawing.title')}</div>
        <img className="mt-5" src="/duck.svg" alt="duck" />
      </div>

      <div className="col-6 text-center d-flex flex-column align-items-center justify-content-center">
        <div
          className="mr-5"
          style={{ position: 'relative' }}
          id="sketch"
          ref={processingRef}
        >
          {!didPaint && (
            <div
              style={{
                width: '704px',
                height: '529px',
                left: 0,
                top: 0,
              }}
              className="position-absolute d-flex align-items-center justify-content-center"
            >
              <div className="drawing-placeholder p-5">
                {t('pages.drawing.instruction')}
              </div>
            </div>
          )}
        </div>
        <Button
          onClick={onReset}
          type="outline-black"
          className="px-2 mt-2 mr-4"
        >
          <img className="mr-2" src="/reset.svg" alt="reset"></img>
          {t('pages.drawing.done')}
        </Button>
      </div>
      {didPaint && (
        <DrawingContext.Consumer>
          {({ drawingUrl, setDrawingUrl }) => (
            <FlowControlBarSingleNextButton
              className="animate__animated animate__fadeIn"
              onClick={() => onFinished(setDrawingUrl)}
              autoHide={false}
              buttonText={t('pages.feedback.button-done')}
            />
          )}
        </DrawingContext.Consumer>
      )}
    </div>
  );
}

export function DrawingMobile({ onNextClick }) {
  const [didPaint, didDidPaint] = useState(false);
  const onPaint = useCallback(() => {
    if (didPaint === false) {
      didDidPaint(true);
    }
  }, [didPaint]);

  const { t } = useTranslation();

  const processingRef = useRef();
  const frameX = 31;
  const frameY = 27;
  const frameW = 376 - frameX * 2;
  const frameH = 283 - frameY * 2;

  useEffect(() => {
    const Sketch = (p) => {
      let canvas;
      const framePos = p.createVector(0, 0);
      const loadedFramePic = p.loadImage('/drawing-frame-small.png');
      const frame = new Rectangle(p, framePos, loadedFramePic);
      p.pixelDensity(1);

      p.hide = () => {
        p.clear();
        // p.background();
      };

      p.setup = () => {
        canvas = p.createCanvas(376, 283);
        canvas.parent('sketch');
        // p.background();
      };

      p.draw = () => {
        frame.draw();
        if (p.mouseIsPressed) {
          if (
            p.mouseX > 31 &&
            p.mouseX < 376 &&
            p.mouseY > 27 &&
            p.mouseY < 283
          ) {
            onPaint();
          }

          p.stroke(0);
          p.strokeWeight(4);
          p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY);
        }
      };
    };

    myP5 = new p5(Sketch, processingRef.current);
  }, []);

  function clip(oldCanvas) {
    var newCanvas = document.createElement('canvas');
    newCanvas.width = frameW;
    newCanvas.height = frameH;
    var newContext = newCanvas.getContext('2d');
    newContext.drawImage(
      oldCanvas,
      frameX,
      frameY,
      frameW,
      frameH,
      0,
      0,
      frameW,
      frameH
    );
    return newCanvas.toDataURL();
  }

  const onFinished = async (setDrawingUrl) => {
    setDrawingUrl(clip(myP5.canvas));
    onNextClick();
  };

  const onReset = () => {
    myP5.hide();
  };

  return (
    <div className="h-100 position-relative bg-radial-gradient d-flex align-items-center justify-content-around">
      <div className="text-center align-items-center justify-content-center px-5">
        <div className="title--small pb-4">{t('pages.drawing.title')}</div>
        <div className="">
          <img className="img-fluid" src="/duck.svg" alt="duck" />
        </div>
      </div>

      <div className="text-center d-flex flex-column align-items-center justify-content-center px-5">
        <div
          className="mx-auto"
          style={{ position: 'relative' }}
          id="sketch"
          ref={processingRef}
        >
          {!didPaint && (
            <div
              style={{
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
              }}
              className="position-absolute d-flex align-items-center justify-content-center"
            >
              <div className="drawing-placeholder--small p-5">
                {t('pages.drawing.instruction')}
              </div>
            </div>
          )}
        </div>
        <div className="d-flex jusify-content-center align-items-center">
          <Button
            disabled={!didPaint}
            onClick={onReset}
            type="default-no-border"
            className="px-2 mr-2"
          >
            <img className="mr-2" src="/reset.svg" alt="reset"></img>
            {t('pages.drawing.done')}
          </Button>
          <DrawingContext.Consumer>
            {({ drawingUrl, setDrawingUrl }) => (
              <Button
                disabled={!didPaint}
                type="success"
                onClick={() => onFinished(setDrawingUrl)}
              >
                {t('pages.feedback.button-done')}
              </Button>
            )}
          </DrawingContext.Consumer>
        </div>
      </div>
    </div>
  );
}

export default Drawing;
