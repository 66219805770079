import React from 'react';
import FlowTitle from '../FlowTitle';
import { useTranslation } from 'react-i18next';
import FlowControlBar from '../FlowControlBar';
import Button from '../Button';

function PdfScore({ onNextClick }) {
  const { t } = useTranslation();

  function onPrint() {
    const pdfWindow = window.open(`/${t('pages.pdfScore.pdf')}`);
    pdfWindow.print();
  }

  return (
    <div className="pdf-container">
      <FlowTitle className="flex-grow-0 py-0">
        {t('pages.pdfScore.title')}
      </FlowTitle>
      <div className="d-flex text-center align-items-center justify-content-center flex-grow-1">
        <div className="col-6 title text-center">
          {t('pages.pdfScore.instruction')}
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center flex-grow-0 my-0 py-0">
        <div className="mx-5">
          <img className="pdf-score mx-3" src="/partitur.png" alt="score"></img>
          <img
            className="pdf-score-instructions mx-3"
            src={`/${t('pages.pdfScore.score')}`}
            alt="score"
          ></img>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center flex-grow-1 mt-0 mb-5">
        <Button
          onClick={onPrint}
          type="outline-black"
          className="px-2 mt-2 mr-4"
        >
          <img className="mr-2" src="/print.svg" alt="print"></img>
          {t('pages.pdfScore.print')}
        </Button>
      </div>
      <FlowControlBar>
        <div className="text-center d-flex align-items-center justify-content-center">
          {t('pages.pdfScore.areYouDone')}
          <Button
            className="px-2 py-1 ml-3"
            type="success"
            onClick={onNextClick}
          >
            {t('pages.pdfScore.next')}
          </Button>
        </div>
      </FlowControlBar>
    </div>
  );
}

export default PdfScore;
