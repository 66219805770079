import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function TimeSelectionLink({ minutes }) {
  return (
    <NavLink
      to={`/experience/${minutes}`}
      className="home__main-card__btn-time"
    >
      <div>
        <img className="img-fluid" src={`/clock-${minutes}.svg`} />
      </div>
      {minutes} min.
    </NavLink>
  );
}

TimeSelectionLink.propTypes = {
  minutes: PropTypes.number.isRequired,
};

export default TimeSelectionLink;
