import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

function LinkButton({ className, type, to, children }) {
  const typeClass = `btn-${type}`;
  return (
    <Link to={to} className={cx('btn', typeClass, className)}>
      {children}
    </Link>
  );
}

LinkButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['default', 'danger', 'success', 'outline-white']),
};

LinkButton.defaultProps = { className: '', type: 'default' };

export default LinkButton;
