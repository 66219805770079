import React from 'react';
import PropTypes from 'prop-types';

function Logo({ className, isWhite }) {
  let logoFileName = 'logo';
  if (isWhite) {
    logoFileName += '-white';
  }
  return (
    <img
      className={className}
      src={`/${logoFileName}.svg`}
      alt="DieOrdnungDerDinge"
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  isWhite: PropTypes.bool,
};

Logo.defaultProps = {
  className: '',
  isWhite: false,
};

export default Logo;
