import React, { useState } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import useMedia from '../../hooks/useMedia';
import { getSingleVideoSrc } from '../../utils';
import MediaControlsBar from '../MediaControlsBar';
import Button from '../Button';

function VideoWithPrompt({
  item,
  onNextClick,
  onPrevClick,
  showPrevButton,
  onEndClick,
  language,
  flowState,
}) {
  const { t } = useTranslation();
  const [isPromptShowing, setIsPromptShowing] = useState(false);
  const [didPromptShow, setDidPromptShow] = useState(false);
  const stepState = flowState[item.id] || {};
  const selectedMediaIndex = stepState.selectedMediaIndex || 0;
  const videoSrc = getSingleVideoSrc(item, language, selectedMediaIndex);
  const title = item.media[selectedMediaIndex].title || item.title;
  const { promptUrl, promptRange } = item;
  const {
    progress,
    isPlaying,
    mediaRef: videoRef,
    onSeek,
    togglePlay: toggleMainVideoPlay,
    time,
  } = useMedia({
    onEnd: onNextClick,
    src: videoSrc,
    autoPlay: true,
  });

  const { mediaRef: promptRef, togglePlay: togglePromptPlay } = useMedia({
    onEnd: noop,
    src: promptUrl,
    autoPlay: false,
  });

  function onNext() {
    const timeInMs = time * 10000;
    if (
      !didPromptShow &&
      timeInMs > promptRange[0] &&
      timeInMs < promptRange[1]
    ) {
      setIsPromptShowing(true);
      setDidPromptShow(true);

      // Stops the playing video and plays the other
      toggleMainVideoPlay();
      togglePromptPlay();
    } else {
      onNextClick();
    }
  }

  function onResume() {
    setIsPromptShowing(false);
    // Stops the playing video and plays the other
    toggleMainVideoPlay();
    togglePromptPlay();
  }
  return (
    <div className="h-100 position-relative d-flex bg-radial-gradient animate__animated animate__fadeIn">
      <video
        key={videoSrc}
        ref={videoRef}
        className="position-absolute h-100 w-100"
      >
        <source type="video/webm" src={videoSrc} />
      </video>
      <video
        key={promptUrl}
        ref={promptRef}
        className={cx('h-100 w-100 position-absolute', {
          'animate__animated animate__fadeOut': !isPromptShowing,
          'animate__animated animate__fadeIn': isPromptShowing,
        })}
      >
        <source type="video/webm" src={promptUrl} />
      </video>
      <div
        className="position-absolute"
        style={{ right: '1rem', bottom: '5rem' }}
      >
        {isPromptShowing && (
          <Button onClick={onResume} type="success" className="shadow mr-2">
            {t('pages.videoWithPrompt.buttons.resume')}
          </Button>
        )}
        <Button
          onClick={isPromptShowing ? onNextClick : onNext}
          type="danger"
          className="shadow"
        >
          {t('pages.videoWithPrompt.buttons.leave')}
        </Button>
      </div>
      <MediaControlsBar
        hide={isPromptShowing}
        togglePlay={toggleMainVideoPlay}
        onEndClick={onEndClick}
        onNextClick={onNext}
        onPrevClick={onPrevClick}
        showPrevButton={showPrevButton}
        isPlaying={isPlaying}
        item={{ title }}
        progress={progress}
        onSeek={onSeek}
      />
    </div>
  );
}

export default VideoWithPrompt;
