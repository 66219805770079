export const FLOW_ITEM_TYPES = {
  VIDEO_SINGLE: 'videoSingle',
  VIDEO_STADT: 'videoStadt',
  VIDEO_DRAWING: 'videoDrawing',
  ASMR: 'ASMR',
  AUDIO: 'audio',
  WHERE_NEXT: 'whereNext',
  PDF_SCORE: 'pdfScore',
  ENSEMBLE_POLL: 'ensemblePoll',
  CHOOSE_MUSIC: 'chooseMusic',
  DRAWING: 'drawing',
  WHO_SHOULD_TELL_YOU: 'whoShouldTellYou',
  YES_NO_VIDEO: 'yesNoVideo',
  ENSEMBLE_POLL_RESULTS: 'ensemblePollResults',
  ENSEMBLE_POLL_INTRO: 'ensemblePollIntro',
};

export const FLOW_MEDIA_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
  AUDIO: 'audio',
};
