import { FLOW_ITEM_TYPES, FLOW_MEDIA_TYPES } from './constants';

export default [
  {
    id: 'SILENCE',
    title: 'pages.flowSelection.silence',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/silence-stopmotion.webm',
    loadingTitleKey: 'loadingText.silence.title',
    loadingTextKey: 'loadingText.silence.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.silenceMustBe',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/silence-must-be_v2.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.silence',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url: 'https://d1y6be8ryibzao.cloudfront.net/videos/silence.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'HITS',
    title: 'pages.flowSelection.hits',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/HITS-stopmotion.webm',
    loadingTitleKey: 'loadingText.hits.title',
    loadingTextKey: 'loadingText.hits.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.tableMusic',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            multilingual: true,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/musiquedetables-de.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.elOrdenDeLasCosas',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/el-orden-de-las-cosas.webm',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.clappingMusic',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'RUHIG_BEWEGT',
    title: 'pages.flowSelection.ruhigBewegt',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/ruhig-bewegt-stopmotion.webm',
    loadingTitleKey: 'loadingText.ruhig-bewegt.title',
    loadingTextKey: 'loadingText.ruhig-bewegt.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.ruhigBewegt',
        media: [
          // TODO: play a different one for English speaking users
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            multilingual: true,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/wagner-beschreibung-de.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'PITCH',
    title: 'pages.flowSelection.pitch',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/pitch-stopmotion.webm',
    requiresHeadphones: true,
    loadingTitleKey: 'loadingText.pitch.title',
    loadingTextKey: 'loadingText.pitch.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.AUDIO,
        title: 'tracks.gehörverlust',
        media: [
          // TODO: play a different one for English speaking users
          {
            multilingual: true,
            type: FLOW_MEDIA_TYPES.AUDIO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/gehoerverlust-de.mp3',
          },
        ],
      },
      {
        type: FLOW_ITEM_TYPES.ASMR,
        title: 'tracks.asmrPlayground',
        media: [],
      },
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.cavatina',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/asmr-cavatina.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'APPLAUS',
    title: 'pages.flowSelection.applaus',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/applause-stopmotion.webm',
    loadingTitleKey: 'loadingText.applause.title',
    loadingTextKey: 'loadingText.applause.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.clappingWorkshop',
        promptRange: [(60 * 2 + 40) * 10000, (60 * 4 + 30) * 10000],
        promptUrl:
          'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music-workshop-exit.webm',
        clapping: true,
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            title: 'tracks.clappingWorkshop',
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/clapping-music-workshop.webm',
          },
        ],
      },
    ],
  },
  {
    id: 'THEMA_VARIATION',
    title: 'pages.flowSelection.themaVariation',
    stopMotion:
      'https://d1y6be8ryibzao.cloudfront.net/videos/themavar-stopmotion.webm',
    loadingTitleKey: 'loadingText.themavar.title',
    loadingTextKey: 'loadingText.themavar.text',
    items: [
      {
        type: FLOW_ITEM_TYPES.VIDEO_SINGLE,
        title: 'tracks.themaVariation',
        media: [
          {
            type: FLOW_MEDIA_TYPES.VIDEO,
            url:
              'https://d1y6be8ryibzao.cloudfront.net/videos/thema-variation-ver3.webm',
          },
        ],
      },
    ],
  },
];
