class Rectangle {
  constructor(p, pos, img, scale) {
    this.p = p;
    this.pos = pos;
    this.img = img;
    this.width = Math.min(img.width, img.width*scale)
    this.height = Math.min(img.height, img.height*scale)    
  }

  draw() {
    this.p.image(this.img, this.pos.x, this.pos.y, this.width, this.height);
  }

  hits(hitpos) {
    if (
      hitpos.x > this.pos.x &&
      hitpos.x < this.pos.x + this.width &&
      hitpos.y > this.pos.y &&
      hitpos.y < this.pos.y + this.height
    ) {
      return true;
    }
    return false;
  }
}

export default Rectangle