import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function FlowControlBar({ children, className, hide }) {
  return (
    <div
      className="position-absolute w-100 overflow-hidden"
      style={{ height: '66px', bottom: 0 }}
    >
      <div
        className={cx(
          'flow-control-bar h-100 position-relative w-100 text-white animate__animated',
          {
            animate__slideInUp: !hide,
            animate__slideOutDown: hide,
          },
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

FlowControlBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hide: PropTypes.bool,
};

FlowControlBar.defaultProps = {
  className: '',
  hide: false,
};

export default FlowControlBar;
