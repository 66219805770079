import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FlowSelectionMinutesNav from './FlowSelectionMinutesNav';
import cx from 'classnames';

const MINUTE_OPTIONS = [5, 10, 20];

function FlowSelectionMenu(props) {
  const { selectedFlow, isVideoSmall, flows, userTime, onFlowSelect } = props;
  const { t } = useTranslation();

  return (
    <div
      className="text-center position-absolute d-flex flex-column flow-selection-menu__container"
      style={{
        opacity: selectedFlow === null ? 1 : 0,
      }}
    >
      <FlowSelectionMinutesNav minuteOptions={MINUTE_OPTIONS} />
      <ul
        className={cx(
          'list-unstyled d-flex flex-column justify-content-center flex-grow-1',
          {
            'mt-4': !isVideoSmall,
            'mt-2': isVideoSmall,
          }
        )}
      >
        <div>
          {flows.map((flow) => (
            <li
              key={flow.id}
              className={cx({
                'my-2': !isVideoSmall,
                'my-0': isVideoSmall,
              })}
            >
              <Link
                onClick={(evt) => onFlowSelect(evt, flow)}
                className={cx({
                  'experience-page-link': !isVideoSmall,
                  'experience-page-link experience-page-link-small': isVideoSmall,
                })}
                to={`/experience/${userTime}/${flow.id}/0`}
              >
                {t(flow.title)}
              </Link>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
}

export default FlowSelectionMenu;
