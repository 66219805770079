import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

function HomeTeamPictureBubble({ isAboutShowing, onScrollClick }) {
  const { t } = useTranslation();

  return (
    <div className={cx('home__team-picture-bubble position-relative')}>
      <img src="/ensemble.png" className="img-fluid position-relative" />
      <button
        onClick={onScrollClick}
        style={{
          transition: 'opacity 0.3s',
          opacity: isAboutShowing ? 0 : 1,
          top: 75,
        }}
        className="btn p-0 home__slide-link font-weight-600 shadow-none position-absolute"
      >
        <span className="d-block">{t('pages.home.link-about-us')}</span>
        <img
          className="mt-2 animate__animated animate__shakeY animate__delay-2s animate__slower animate__repeat-2"
          src="/chevron-down.svg"
        />
      </button>
    </div>
  );
}

HomeTeamPictureBubble.propTypes = {
  isLifted: PropTypes.bool.isRequired,
  onScrollClick: PropTypes.func.isRequired,
};

export default HomeTeamPictureBubble;
