import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import cx from 'classnames';

function ModalHeader() {
  return <div />;
}

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

function OddModal({ isOpen, onClose, children, modalKey, dialogClassName }) {
  return (
    <Modal
      key={modalKey}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={modalKey}
      className={cx('modal-dialog modal-dialog-centered', dialogClassName)}
    >
      <div className="modal-content p-0">
        <ModalHeader onClose={onClose} />
        <div className="modal-body">{children}</div>
      </div>
    </Modal>
  );
}

OddModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  modalKey: PropTypes.string,
  dialogClassName: PropTypes.string,
};

OddModal.defaultProps = {
  isOpen: false,
  customerId: null,
  dialogClassName: '',
  modalKey: 'default-modal',
};

export default OddModal;
