import React from 'react';
// import PropTypes from 'prop-types';
// import cx from 'classnames';
import LogoType from '../components/LogoType';
import Logo from '../components/Logo';
import { useTranslation } from 'react-i18next';
import LinkButton from '../components/LinkButton';
import { useParams } from 'react-router';

const creditsConfig = [
  {
    roleString: 'artistic-direction',
    name: 'Vera Kardos & Iñigo Giner Miranda',
  },
  {
    roleString: 'equipment',
    name: 'Rózsa Sebő',
  },
  {
    roleString: 'dramaturgy',
    name: 'Franziska Seeberg',
  },
  {
    roleString: 'video-and-editing',
    name: 'Quiet City',
  },
  {
    roleString: 'sound',
    name: 'Vazul Endre Mandli',
  },
  {
    roleString: 'programming',
    name: 'Yoav Pasovsky & Ofir Weinstock',
  },
  {
    roleString: 'webdesign',
    name: 'Tero Saarinen',
  },
  {
    roleString: 'production',
    name: 'Kirsten Junglas',
  },
  {
    roleString: 'animation',
    name: 'Iñigo Giner Miranda',
  },
];

const classNameForContainer =
  'position-absolute w-100 bg-dark-purple h-100 d-flex flex-column align-items-center justify-content-center';

const creditsClassName =
  'credits text-white animate__animated animate__faster animate__fadeIn';

function Credits({ getFlow }) {
  const { t } = useTranslation();
  const { flowId } = useParams();
  const pageT = (key, config) => t(`pages.feedback.credits.${key}`, config);
  return (
    <div
      className={classNameForContainer}
      style={{ top: 0, left: 0, zIndex: 1 }}
    >
      <div className={creditsClassName}>
        <LogoType />
        <div className="credits-roll__container">
          <div className="credits-roll__roller">
            <p className="mb-4">{pageT('subtitle')}</p>
            <Logo className="mb-2" isWhite />
            <p className="mb-5">
              Vera Kardos, Iñigo Giner Miranda, Meriel Price, Cathrin Romeis
            </p>
            <div className="mb-5">
              <p>{pageT('program')}</p>
              <p>
                {pageT(`programs.${flowId}`, {
                  returnObjects: true,
                }).map((line) => (
                  <span key={line}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            </div>
            <ul className="list-unstyled mb-5">
              {creditsConfig.map(({ roleString, name }) => (
                <li
                  key={roleString}
                  className="d-flex justify-content-between mb-2"
                >
                  <span className="flex-grow-0">{pageT(roleString)}</span>
                  <span className="flex-grow-1 bb-dotted-1" />
                  <span className="flex-grow-0">{name}</span>
                </li>
              ))}
            </ul>

            <p>{pageT('supported-by-senate')}</p>
            <p>
              {pageT('special-thanks')}
              <br />Ⓒ dieordnungderdinge2020
            </p>
          </div>
        </div>
      </div>
      <div
        className="text-center pt-5 position-absolute"
        style={{ bottom: '20px', right: '20px' }}
      >
        <LinkButton to="/experience/5" type="success">
          {t('pages.feedback.button-choose-a-soundtrack')}
        </LinkButton>
      </div>
    </div>
  );
}

Credits.propTypes = {};

Credits.defaultProps = {};

export default Credits;
