import { useRef, useState, useEffect, useCallback } from 'react';

function useMedia({ onEnd, src, autoPlay, initialTime }) {
  const mediaRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState(0);

  const togglePlay = () => {
    if (isPlaying) {
      mediaRef.current.pause();
    } else {
      mediaRef.current.play();
    }
  };

  const onSeek = (values) => {
    mediaRef.current.currentTime =
      (mediaRef.current.duration * values[0]) / 100;
  };

  const onTimeUpdate = useCallback((evt) => {
    const { target } = evt;
    const progress = Math.round((target.currentTime / target.duration) * 100);
    setProgress(progress);
    setTime(target.currentTime);
  }, []);

  const onMediaEnd = useCallback(() => {
    setIsPlaying(false);
    onEnd();
  }, [onEnd]);

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onMediaPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const el = mediaRef.current;
    setProgress(0);

    el.addEventListener('play', onPlay);
    el.addEventListener('timeupdate', onTimeUpdate);
    el.addEventListener('seeked', onTimeUpdate);
    el.addEventListener('pause', onMediaPause);

    if (initialTime) {
      mediaRef.current.currentTime = initialTime;
    }
    if (autoPlay) {
      el.play().catch((err) => {
        if (err.name === 'NotAllowedError') {
          el.muted = true;
          el.play();
        }
      });
    }

    return () => {
      el.removeEventListener('play', onPlay);
      el.removeEventListener('timeupdate', onTimeUpdate);
      el.removeEventListener('seeked', onTimeUpdate);
      el.removeEventListener('pause', onMediaPause);
      el.pause();
    };
  }, [src, autoPlay]);

  useEffect(() => {
    const el = mediaRef.current;
    el.addEventListener('ended', onMediaEnd);
    return () => {
      el.removeEventListener('ended', onMediaEnd);
    };
  }, [onMediaEnd, src, autoPlay]);

  return { mediaRef, togglePlay, onSeek, isPlaying, progress, autoPlay, time };
}

export default useMedia;
