import { useEffect } from 'react';

function useTimeout({ onTimeout, condition, duration }) {
  useEffect(() => {
    let timeout;

    if (condition) {
      timeout = setTimeout(() => {
        onTimeout();
      }, duration);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [condition]);
}

export default useTimeout;
