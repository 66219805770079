import { useEffect, useState, useRef, useCallback } from 'react';
import useTimeout from './useTimeout';

function useHoverAndClick({ timeoutDuration }) {
  const [isHovered, setValue] = useState(false);
  const [isClicked, setValue2] = useState(false);
  const [isShowing, setIsShowing] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  const handleClick = useCallback(() => setValue2(!isClicked), [isClicked]);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        node.addEventListener('click', handleClick);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
          node.removeEventListener('click', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  useEffect(() => {
    if (isHovered) {
      setIsShowing(true);
    }
  }, [isHovered]);

  useEffect(() => {
    if (isClicked) {
      setIsShowing(true);
    }
  }, [isClicked]);

  const condition = (isShowing && !isHovered) || (isShowing && isClicked);

  useTimeout({
    onTimeout: () => {
      debugger;
      setIsShowing(false);
      setValue2(false);
    },
    condition,
    duration: timeoutDuration,
  });

  return [ref, isShowing];
}

export default useHoverAndClick;
