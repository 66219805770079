import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import Button from './Button';
import Curtains from './Curtains';
import useP5 from '../hooks/useP5';
import FlowControlBar from './FlowControlBar';
import FlowControlBarSingleNextButton from './FlowControlBarSingleNextButton';
import useEmail from '../hooks/useEmail';
import useWindowSize from '../hooks/useWindowSize';
import useCalculateLayout from '../hooks/useCalculateLayout';
import cx from 'classnames';

function FeedbackSummary({ imageDataUrl, onNextClick }) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center h-100 flex-column'
      )}
    >
      <div className="col-9">
        <h5 className="feedback__title text-center">
          {t('pages.feedback.summary.title')}:
        </h5>
        <img className="img-fluid" src={imageDataUrl} />
        <div className="text-center d-flex align-items-center justify-content-center mt-lg-3 mt-2">
          <Button onClick={onNextClick} type="success">
            {t('common.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

function FeedbackOptionalText({
  onFeedbackTextChange,
  feedbackText,
  isFeedbackSubmitted,
  windowHeight,
  onSkip,
  onSendFeedback,
}) {
  const { t } = useTranslation();
  function onTextAreaChange(evt) {
    onFeedbackTextChange(evt.target.value);
  }

  return (
    <div
      className={cx(
        'd-flex justify-content-center align-items-center h-100 flex-column'
      )}
    >
      <div className="col-10">
        {!isFeedbackSubmitted && (
          <>
            <label className="title mb-3 mt-1 mb-lg-4">
              {t('pages.feedback.summary.textarea-label')}
            </label>
            <textarea
              placeholder={t('pages.feedback.summary.textarea-placeholder')}
              rows={windowHeight > 1000 ? 5 : 4}
              className="p-3 w-100 feedback-textarea"
              value={feedbackText}
              onChange={onTextAreaChange}
            />
          </>
        )}
        <div className="text-center d-flex align-items-center justify-content-center py-3">
          <Button onClick={onSkip} type="default-no-border" className="mr-3">
            {t('pages.feedback.button-skip')}
          </Button>
          <Button onClick={onSendFeedback} type="success">
            {t('pages.feedback.button-send')}
          </Button>
        </div>
      </div>
    </div>
  );
}

function Feedback({ getFlow, lambdaUrl }) {
  const FRAME_WIDTH = 917;
  const FRAME_HEIGHT = 689;
  const ref = useRef();
  const [isDragged, setIsDragged] = useState(false);
  const [scale, setScale] = useState(1);
  const onDrag = useCallback(() => {
    if (isDragged === false) {
      setIsDragged(true);
    }
  }, [isDragged]);
  const [isReady, setIsReady] = useState(false);
  const windowSize = useWindowSize();
  const frameW = Math.min(FRAME_WIDTH, FRAME_WIDTH * scale);
  const frameH = Math.min(FRAME_HEIGHT, FRAME_HEIGHT * scale);
  const w = windowSize.width;
  const h = windowSize.height;
  const frameX = (windowSize.width - frameW) / 2;
  const frameY = 26 + (windowSize.height - frameH) / 2;

  const { calculateASMRLayout } = useCalculateLayout();

  useEffect(() => {
    if (isNaN(windowSize.width)) {
      return;
    }
    const { scale } = calculateASMRLayout({ windowSize });
    setScale(scale);
    if (!isReady) {
      setIsReady(true);
    }
  }, [windowSize]);

  const { sketch } = useP5({
    w,
    h,
    frameH,
    frameW,
    frameX,
    frameY,
    textLines: ['line1', 'line2'],
    onDrag,
    ref,
    scale,
    isReady,
  });

  const sendEmail = useEmail({ baseURL: lambdaUrl });
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { flowId, userTime } = useParams();
  const flow = getFlow(userTime, flowId);
  const [step, setStep] = useState(0);
  const [dataUrl, setDataUrl] = useState('');
  const [areCurtainsClosed, setAreCurtainsClosed] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  function clip(oldCanvas) {
    var newCanvas = document.createElement('canvas');
    newCanvas.width = frameW;
    newCanvas.height = frameH;
    var newContext = newCanvas.getContext('2d');
    newContext.drawImage(
      oldCanvas,
      frameX,
      frameY,
      frameW,
      frameH,
      0,
      0,
      frameW,
      frameH
    );
    return newCanvas.toDataURL();
  }
  function onNextStepClick() {
    setDataUrl(clip(sketch.canvas));
    setStep(step + 1);
  }

  function onSendFeedback() {
    const route = t(flow.title);
    const text = feedbackText;
    const lang = i18n.language.split('-')[0] === 'de' ? 'de' : 'en';
    sendEmail({ route, text, lang });
    setIsFeedbackSubmitted(true);
  }

  function onSkip() {
    setIsFeedbackSubmitted(true);
  }

  useEffect(() => {
    let timeout;

    if (isFeedbackSubmitted) {
      setAreCurtainsClosed(true);
      timeout = setTimeout(() => {
        history.push(`/credits/${flowId}`);
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isFeedbackSubmitted]);

  let curtainMode = 'invisible';

  if (step === 1 || step === 2) {
    curtainMode = 'default';
  }

  if (areCurtainsClosed) {
    curtainMode = 'closed';
  }

  return (
    <>
      <div className="h-100 position-relative bg-radial-gradient d-flex flex-column align-items-center overflow-hidden">
        {step === 0 && isReady && (
          <div className="h-100 d-flex justify-content-center flex-column position-relative">
            <div ref={ref} className="position-relative">
              {!isDragged && (
                <div
                  style={{
                    width: `${frameW}px`,
                    height: `${frameH}px`,
                    left: frameX,
                    top: frameY,
                  }}
                  className="position-absolute d-flex align-items-center flex-column justify-content-center"
                >
                  <div className="feedback-drag-placeholder">
                    <p className="mb-lg-5">
                      {t('pages.feedback.drag-and-drop.instructions.line1')}
                    </p>
                    <p>
                      {t('pages.feedback.drag-and-drop.instructions.line2')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {step === 1 && (
          <FeedbackSummary
            imageDataUrl={dataUrl}
            onNextClick={() => setStep(2)}
          />
        )}
        {step === 2 && (
          <FeedbackOptionalText
            onFeedbackTextChange={setFeedbackText}
            isFeedbackSubmitted={isFeedbackSubmitted}
            feedbackText={feedbackText}
            windowHeight={h}
            onSkip={onSkip}
            onSendFeedback={onSendFeedback}
          />
        )}
      </div>
      {step === 0 && (
        <FlowControlBarSingleNextButton
          onClick={onNextStepClick}
          buttonText={t('pages.feedback.button-done')}
        />
      )}
      <Curtains mode={curtainMode} />
    </>
  );
}

Feedback.propTypes = {
  lambdaUrl: PropTypes.string.isRequired,
  getFlow: PropTypes.func.isRequired,
};

export default Feedback;
