import React from 'react';
import { Range } from 'react-range';
import { useTranslation } from 'react-i18next';
import FlowControlBar from './FlowControlBar';

function MediaControlsBar({
  togglePlay,
  onEndClick,
  onNextClick,
  onPrevClick,
  showPrevButton,
  isPlaying,
  item,
  progress,
  hide,
  onSeek,
  showRestart,
  onRestart,
}) {
  const { t } = useTranslation();

  return (
    <FlowControlBar hide={hide}>
      <Range
        step={0.1}
        min={0}
        max={100}
        draggableTrack={false}
        values={[progress]}
        onChange={onSeek}
        renderTrack={({ props, children }) => (
          <div className="video-controls__progress-bar" {...props}>
            {children}
          </div>
        )}
        renderThumb={({ props }) => {
          return (
            <div
              {...props}
              className="video-controls__progress-bar__thumb"
              style={{
                ...props.style,
                width: `${props['aria-valuenow']}%`,
              }}
            />
          );
        }}
      />
      <div className="d-flex align-items-center justify-content-between mt-1">
        <div className="d-flex align-items-center">
          <button
            className="btn video-controls__btn shadow-none p-0 mr-2"
            type="button"
            onClick={togglePlay}
          >
            {isPlaying ? (
              <i className="icon-pause" />
            ) : (
              <i className="icon-play" />
            )}
          </button>
          <span className="shadow-none p-0 video-controls__title">
            {t(item.title)}
          </span>

          {showPrevButton && (
            <button
              className="btn video-controls__btn shadow-none p-0 ml-2"
              type="button"
              onClick={onPrevClick}
            >
              <i className="icon-prev" />
            </button>
          )}

          {showRestart && (
            <button
              className="btn video-controls__btn shadow-none p-0 ml-2"
              type="button"
              onClick={onRestart}
            >
              <i className="icon-prev" />
            </button>
          )}
          <button
            className="btn video-controls__btn shadow-none p-0 ml-2"
            onClick={onNextClick}
          >
            <i className="icon-next" />
          </button>
        </div>
        <button
          className="btn video-controls__btn shadow-none p-0"
          onClick={onEndClick}
        >
          <i className="icon-stop mr-1" />
          {t('controls.endButton')}
        </button>
      </div>
    </FlowControlBar>
  );
}

export default MediaControlsBar;
