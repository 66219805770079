import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function TeamPictureBubble({ className }) {
  return <img src="/ensemble.png" className="img-fluid" />;
}

TeamPictureBubble.propTypes = {
  className: PropTypes.string,
};

TeamPictureBubble.defaultProps = {
  className: 'position-absolute',
};

export default TeamPictureBubble;
