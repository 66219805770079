import React, { useState } from 'react';
import noop from 'lodash/noop';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import useMedia from '../../hooks/useMedia';
import FlowTitle from '../FlowTitle';
import { sleep } from '../../utils';
import useHover from '../../hooks/useHover';

function EnsemplePollOption({
  onEnd,
  src,
  onVideoSelected,
  title,
  index,
  isSelected,
  isSlidingOut,
  disable,
}) {
  const { mediaRef } = useMedia({ src, onEnd, autoPlay: true });

  const [ref, isHover] = useHover();
  const isAnimatingThisOption = isSelected || isSlidingOut;

  async function onOptionClick() {
    onVideoSelected(index);
  }

  const enableHover = !isAnimatingThisOption;
  const boxClasses = cx(
    'ensemble-poll-option__overlay__box  w-100 d-flex align-items-center justify-content-center',
    {
      'animate__animated animate__faster': false,
      animate__fadeInUp:
        (enableHover && isHover) || (isSelected && !isSlidingOut),
      animate__fadeOutDown: !isHover || isSlidingOut,
    }
  );

  return (
    <div className="h-100 position-relative flex-grow-1">
      <video
        muted
        key={src}
        ref={mediaRef}
        loop
        className="w-100 h-100 bg-white"
      >
        <source type="video/webm" src={src} />
      </video>
      <div
        ref={ref}
        className={cx(
          'ensemble-poll-option__overlay flex-column d-flex justify-content-center'
        )}
        onClick={isAnimatingThisOption || disable ? noop : onOptionClick}
      >
        <div className={boxClasses}>
          <div className="ensemble-poll-option__overlay__mark mr-3">
            {isSelected && (
              <div className="ensemble-poll-option__overlay__mark__fill animate__animated animate__bounceIn animate__faster" />
            )}
          </div>
          {title}
        </div>
      </div>
    </div>
  );
}

function EnsemblePoll(props) {
  const { t } = useTranslation();
  const { item, setStateForStep } = props;
  const { questions } = item;
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [correctAnswers, setcorrectAnswers] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [slideOutIndex, setSlideOutIndex] = useState(null);

  async function animateIndex(index) {
    setSelectedIndex(null);
    setSlideOutIndex(null);

    setSelectedIndex(index);
    await sleep(400);
    setSlideOutIndex(index);

    await sleep(500);
    setSelectedIndex(null);
    setSlideOutIndex(null);
  }

  async function onAnswer(answerIndex) {
    animateIndex(answerIndex);
    setIsLoadingQuestion(true);
    const isCorrect = questions[currentQuestion].answers.includes(answerIndex);

    const newCorrectAnswers = correctAnswers + Boolean(isCorrect);
    setcorrectAnswers(newCorrectAnswers);

    await sleep(400);

    const nextIndex = currentQuestion + 1;
    if (!!questions[nextIndex]) {
      setCurrentQuestion(nextIndex);
    } else {
      setStateForStep([
        {
          stepId: 'ensemblePollResults',
          state: {
            percentage: Math.floor(
              (newCorrectAnswers / questions.length) * 100
            ),
          },
        },
      ]);
      props.onNextClick();
    }

    setIsLoadingQuestion(false);
  }

  return (
    <div className="h-100 d-flex flex-column animate__animated animate__slow animate__fadeIn">
      <FlowTitle
        key={currentQuestion}
        className={cx('animate__animated', {
          animate__slideOutUp: isLoadingQuestion,
          animate__slideInDown: !isLoadingQuestion,
        })}
      >
        <span key={currentQuestion}>
          {t(`pages.ensemblePoll.question${currentQuestion}`)}
        </span>
      </FlowTitle>
      <div className="d-flex flex-grow-1 overflow-hidden justify-content-between">
        {item.media.map((media, i) => (
          <EnsemplePollOption
            key={media.url + i}
            onEnd={noop}
            title={media.title}
            index={i}
            onVideoSelected={onAnswer}
            src={media.url}
            isSelected={selectedIndex === i}
            isSlidingOut={slideOutIndex === i}
            disable={selectedIndex !== null && selectedIndex !== i}
          />
        ))}
      </div>
    </div>
  );
}

export default EnsemblePoll;
