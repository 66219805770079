import React from 'react';
import { useTranslation } from 'react-i18next';
import FlowTitle from '../FlowTitle';
import Button from '../Button';
import FlowControlBar from '../FlowControlBar';
import TeamPictureBubble from '../TeamPictureBubble';

function EnsemblePollIntro(props) {
  const { item, onNextClick, flowState } = props;
  const { t } = useTranslation();

  return (
    <div className="h-100 position-relative">
      <FlowTitle className="position-absolute" style={{ top: '0' }}>
        {t('pages.ensemblePoll.intro.title')}
      </FlowTitle>
      <div className="h-100 d-flex justify-content-center flex-lg-column align-items-center px-5">
        <p className="text-center ensemble-poll-intro__text mb-0 mb-lg-5">
          {t('pages.ensemblePoll.intro.text1')}
          <br />
          {t('pages.ensemblePoll.intro.text2')}
        </p>
        <div className="ensemble-poll-intro__image">
          <TeamPictureBubble />
        </div>
      </div>
      <FlowControlBar className="text-center">
        <Button onClick={onNextClick} className="text-uppercase" type="success">
          {t('common.next')}
        </Button>
      </FlowControlBar>
    </div>
  );
}

export default EnsemblePollIntro;
