import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import useMedia from '../../hooks/useMedia';
import { getSingleVideoSrc } from '../../utils';
import MediaControlsBar from '../MediaControlsBar';
import { findIndex } from 'lodash';

function VideoStadtControls({
  bgMusicOptions,
  onOptionClick,
  activeBgMediaindex,
  wasOptionSelected,
}) {
  const { t } = useTranslation();
  return (
    <div className="video-stadt__controls">
      <div
        className={cx(
          'video-stadt__controls__title mx-auto py-2 mb-3 animate__animated',
          {
            animate__fadeOut: wasOptionSelected,
          }
        )}
      >
        {t('videoStadt.controls.title')}
      </div>
      <div>
        {bgMusicOptions.map((option, index) => (
          <button
            key={option.title}
            className={cx('btn video-stadt__controls__button mx-2', {
              active: index === activeBgMediaindex,
            })}
            onClick={() => onOptionClick(index)}
          >
            <i className={`icon-${option.icon} mr-1`} />
            {t(option.title)}
          </button>
        ))}
      </div>
    </div>
  );
}

function VideoStadt({
  item,
  onNextClick,
  onPrevClick,
  showPrevButton,
  onEndClick,
  language,
  flowState,
}) {
  const stepState = flowState[item.id] || {};
  const selectedMediaIndex = stepState.selectedMediaIndex || 0;
  const videoSrc = getSingleVideoSrc(item, language, selectedMediaIndex);
  const title = item.media[selectedMediaIndex].title || item.title;
  const bgMedia = item.bgMedia[language];
  const firstMusicIndex = findIndex(bgMedia, (m) => m.icon === 'note');
  const [activeBgMediaindex, setactiveBgMediaindex] = useState(firstMusicIndex);
  const [wasOptionSelected, setWasOptionSelected] = useState(false);

  useEffect(() => {
    if (activeBgMediaindex !== firstMusicIndex) {
      setWasOptionSelected(true);
    }
  }, [activeBgMediaindex]);

  const {
    progress: videoProgress,
    isPlaying: isVideoPlaying,
    mediaRef: videoRef,
    onSeek: onVideoSeek,
    togglePlay: toggleVideoPlay,
    time: videoTime,
  } = useMedia({
    onEnd: onNextClick,
    src: videoSrc,
    autoPlay: true,
    initialTime: 0,
  });

  const bgMediaItem = bgMedia[activeBgMediaindex];
  const bgSrc = bgMediaItem.url;
  const {
    mediaRef: bgMusicRef,
    onSeek: onBgMusicSeek,
    togglePlay: toggleBgMusicPlay,
  } = useMedia({
    onEnd: noop,
    src: bgSrc,
    autoPlay: true,
    initialTime: videoTime,
  });

  function onTogglePlay() {
    toggleBgMusicPlay();
    toggleVideoPlay();
  }

  function onSeek(values) {
    onVideoSeek(values);
    onBgMusicSeek(values);
  }

  const onBgMusicClick = (index) => {
    setactiveBgMediaindex(index);
  };

  return (
    <div className="h-100 position-relative d-flex bg-radial-gradient">
      <video key={videoSrc} ref={videoRef} className="h-100 w-100">
        <source type="video/webm" src={videoSrc} />
      </video>
      <audio key={bgSrc} ref={bgMusicRef}>
        <source type="audio/mp3" src={bgSrc} />
      </audio>
      <VideoStadtControls
        activeBgMediaindex={activeBgMediaindex}
        onOptionClick={onBgMusicClick}
        bgMusicOptions={bgMedia}
        wasOptionSelected={wasOptionSelected}
      />
      <MediaControlsBar
        showRestart
        onRestart={() => onSeek([0])}
        togglePlay={onTogglePlay}
        onEndClick={onEndClick}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        showPrevButton={showPrevButton}
        isPlaying={isVideoPlaying}
        item={{ title }}
        progress={videoProgress}
        onSeek={onSeek}
      />
    </div>
  );
}

export default VideoStadt;
