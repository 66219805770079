import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Logo from '../components/Logo';
import HomeMainCard from '../components/HomeMainCard';
import Curtain from '../components/Curtain';
import HomeTeamPictureBubble from '../components/HomeTeamPictureBubble';
import HomeAboutSlide from '../components/HomeAboutSlide';
import LanguageSelector from '../components/LanguageSelector';

function Home(props) {
  const { i18n } = useTranslation();
  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
  };

  const [isAboutShowing, setIsAboutShowing] = useState(false);
  // useEffect(() => {
  //   const isSupported =
  //     navigator.userAgent.match(/Chrome/i) ||
  //     navigator.userAgent.match(/Firefox/i) ||
  //     navigator.userAgent.match(/Edge/i);
  //   setIsBrowserSupported(isSupported);
  // }, []);

  const ref = useRef();
  const scrollRef = useRef();

  const listener = (evt) => {
    setIsAboutShowing(evt.target.scrollTop >= 590);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', listener);
    }

    return () => {
      scrollRef.current.removeEventListener('scroll', listener);
    };
  }, [scrollRef.current]);

  const onScrollClick = useCallback(() => {
    scrollRef.current.scrollTo({ top: 9999, behavior: 'smooth' });
  }, []);

  return (
    <div className="bg-cream h-100 overflow-hidden position-relative">
      <Curtain position="left" mode="default" />
      <Curtain position="left-bottom" mode="default" />
      <Curtain position="right" mode="default" />
      <Curtain position="right-bottom" mode="default" />

      <div ref={scrollRef} className="position-relative overflow-auto h-100">
        <div className={cx('home__main')}>
          <div className="w-100 text-center mb-3 mb-md-4">
            <Logo className="home__main__logo text-center" />
          </div>
          <div className="px-3">
            <HomeMainCard isBrowserSupported isAboutShowing={isAboutShowing} />
          </div>
          <div className="my-2 my-lg-3">
            <HomeTeamPictureBubble
              onScrollClick={onScrollClick}
              isAboutShowing={isAboutShowing}
            />
          </div>

          <div ref={ref} className={cx('home__about px-3')}>
            <HomeAboutSlide />
          </div>
        </div>
      </div>
      <LanguageSelector
        currentLanguage={i18n.language}
        onLanguageSelect={changeLanguage}
      />
    </div>
  );
}

export default Home;
