import React from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import get from 'lodash/get';
import FlowTitle from '../FlowTitle';
import Button from '../Button';
import FlowControlBar from '../FlowControlBar';
import TeamPictureBubble from '../TeamPictureBubble';

function EnsemblePollResults(props) {
  const { item, onNextClick, flowState } = props;
  const { t } = useTranslation();

  const stepState = flowState[item.id] || {};
  const results = get(stepState, 'percentage', 0);
  return (
    <div className="h-100 position-relative">
      <FlowTitle className="position-absolute" style={{ top: '0' }}>
        {t('pages.ensemblePoll.results')}
      </FlowTitle>
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <div className="position-relative ensemble-poll-results__container">
          <TeamPictureBubble />

          <div className="ensemble-poll-results__title position-absolute">
            <CountUp duration={3} end={results} />%
          </div>
        </div>
      </div>
      <FlowControlBar className="text-center">
        <Button onClick={onNextClick} className="text-uppercase" type="success">
          {t('common.next')}
        </Button>
      </FlowControlBar>
    </div>
  );
}

export default EnsemblePollResults;
