import React from 'react';

function MediaOverlay({ title, onClick }) {
  return (
    <div role="button" className="overlay" onClick={onClick}>
      <div className="overlay__play mb-4 mb-md-0 rounded-circle border d-flex align-items-center justify-content-center border-white">
        <i className="icon-play" />
      </div>
      {title && <div className="overlay__title">{title}</div>}
    </div>
  );
}

export default MediaOverlay;
