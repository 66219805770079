import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from 'tone';
import items from '../asmrData';
import Objects from '../Objects';
import FlowControlBarSingleNextButton from '../FlowControlBarSingleNextButton';
import useWindowSize from '../../hooks/useWindowSize';
import useCalculateLayout from '../../hooks/useCalculateLayout';

let players = [];
const CLICKS_THRESHOLD = 5;

function ASMR({ onNextClick }) {
  const [numberOfClicks, setNumberOfClicks] = useState(0);
  const [scale, setScale] = useState(1);
  const [leftMargin, setLeftMargin] = useState(0);
  const [topMargin, setTopMargin] = useState(0);
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const { calculateASMRLayout } = useCalculateLayout();

  useEffect(() => {
    const { scale, leftMargin, topMargin } = calculateASMRLayout({ windowSize });
    setScale(scale);
    setLeftMargin(leftMargin);
    setTopMargin(topMargin);
  }, [windowSize]);

  const loadPlayers = () => {
    players = items.map((item, index) => {
      const player = new Player({
        url: item.audioUrl,
        loop: true,
      }).toDestination();
      return player;
    });
  };

  const onItemClick = (item, id, index) => {
    setNumberOfClicks(numberOfClicks + 1);

    const div = document.getElementById(id);
    div.className = item.toggled ? item.css : item.cssOn;
    if (item.toggled) {
      div.style.width = `${item.width * Math.min(scale, 1)}px`;
      div.style.height = `${item.height * Math.min(scale, 1)}px`;
      div.style.top = `${topMargin + item.top * Math.min(scale, 1)}px`;
      div.style.left = `${leftMargin + item.left * Math.min(scale, 1)}px`;
    } else {
      div.style.width = `${item.widthOn * Math.min(scale, 1)}px`;
      div.style.height = `${item.heightOn * Math.min(scale, 1)}px`;
      div.style.top = `${topMargin + item.topOn * Math.min(scale, 1)}px`;
      div.style.left = `${leftMargin + item.leftOn * Math.min(scale, 1)}px`;
    }

    item.toggled = !item.toggled;
    const player = players[index];

    if (player && player.loaded) {
      if (player.state === 'stopped') {
        player.start();
      } else {
        player.stop();
      }
    }
  };

  const onDoneClick = () => {
    players.forEach((player) => {
      if (player.state === 'started') {
        player.stop();
      }
    });
    onNextClick();
  };

  return (
    <div className="h-100 container d-flex align-items-center justify-content-center animate__animated animate__fadeIn">
      {numberOfClicks < CLICKS_THRESHOLD && (
        <div className="col-12 d-flex align-items-center">
          <div className="col-6 offset-3 asmr-placeholder px-5 mt-5">
            <p className="mt-5">{t('pages.asmrPlayground.generalText')}</p>
          </div>
        </div>
      )}
      <Objects onItemClick={onItemClick} loadPlayers={loadPlayers}></Objects>
      <FlowControlBarSingleNextButton
        onClick={onDoneClick}
        buttonText={t('pages.feedback.button-done')}
      />
    </div>
  );
}

export default ASMR;
