import React from 'react';
import PropTypes from 'prop-types';
import FlowControlBar from './FlowControlBar';
import Button from './Button';
import cx from 'classnames';

function FlowControlBarSingleNextButton({
  buttonText,
  onClick,
  className,
  durationBeforeHide,
}) {
  return (
    <FlowControlBar
      durationBeforeHide={durationBeforeHide}
      className={className}
    >
      <div className="text-center d-flex align-items-center justify-content-center">
        <Button type="success" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </FlowControlBar>
  );
}

FlowControlBarSingleNextButton.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  autoHide: PropTypes.bool,
  durationBeforeHide: PropTypes.number,
};

FlowControlBarSingleNextButton.defaultProps = {
  className: '',
  durationBeforeHide: 1000,
  autoHide: true,
};

export default FlowControlBarSingleNextButton;
