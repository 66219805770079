import React from 'react';
import PropTypes from 'prop-types';
import Curtain from './Curtain';

function Curtains({ mode }) {
  return (
    <>
      <Curtain position="left" mode={mode} />
      <Curtain position="left-bottom" mode={mode} />
      <Curtain position="right" mode={mode} />
      <Curtain position="right-bottom" mode={mode} />
    </>
  );
}

Curtains.propTypes = {
  mode: PropTypes.oneOf(['default', 'open', 'closed', 'invisible']),
};

Curtains.defaultProps = {
  isFullClosed: false,
};

export default Curtains;
