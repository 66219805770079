import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MediaOverlay from '../MediaOverlay';

function ChooseMusicOption({ url, title, onClick, height }) {
  const isHeadphones = url.indexOf('headphones') > -1;
  return (
    <div
      role="button"
      className={`choose-music__item overflow-hidden position-relative h-${height}`}
      style={{
        backgroundImage: `url(${url})`,
        backgroundSize: isHeadphones ? 'auto' : 'cover',
      }}
    >
      <MediaOverlay onClick={onClick} title={title} />
    </div>
  );
}

function ChooseMusic({ item, setStateForStep, onNextClick, flowState }) {
  const { media } = item;
  const stepState = flowState[item.id] || {};
  const indexToFilterOut = get(stepState, 'selectionIndexToFilterOut', 4);

  const { t } = useTranslation();

  const onClick = (index) => {
    const states = [];
    if (item.id === 'madeForYouChooseMusic') {
      states.push({
        stepId: 'musicChoice',
        state: {
          selectedMediaIndex: index,
        },
      });

      states.push({
        stepId: 'chooseMusicWithSoundwalk',
        state: {
          selectionIndexToFilterOut: index,
        },
      });
    }

    if (item.id === 'chooseMusicWithSoundwalk') {
      states.push({
        stepId: 'musicChoiceWithSoundwalk',
        state: {
          selectedMediaIndex: index,
        },
      });
    }

    if (item.id === 'chooseClapping') {
      states.push({
        stepId: 'clappingChoice',
        state: {
          selectedMediaIndex: index,
          isPromptActive: index === 1,
        },
      });
    }

    setStateForStep(states);

    return onNextClick();
  };

  return (
    <div className="choose-music__container animate__animated animate__fadeIn">
      {media
        .map((media, index) => ({ ...media, originalIndex: index }))
        .filter((media) => media.originalIndex !== indexToFilterOut)
        .map((media) => (
          <ChooseMusicOption
            height={item.id === 'chooseClapping' ? '100' : '50'}
            key={media.url}
            onClick={() => onClick(media.originalIndex)}
            title={t(media.title)}
            url={media.url}
          />
        ))}
    </div>
  );
}

export default ChooseMusic;
