import React from 'react';
import FlowTitle from '../FlowTitle';
import { useTranslation } from 'react-i18next';

function WhereNext({ setStateForStep, onNextClick }) {
  const { t } = useTranslation();

  function onMouseumPinClick() {
    setStateForStep([{
      stepId: 'whereNextResult',
      state: {
        selectedMediaIndex: 0,
      }
    }]);
    onNextClick();
  }

  function onAlexPinClick() {
    setStateForStep([{
      stepId: 'whereNextResult',
      state: {
        selectedMediaIndex: 1,
      }
    }]);
    onNextClick();
  }

  return (
    <div className="h-100 d-flex flex-column">
      <FlowTitle className="flex-grow-0">{t('pages.map.text')}</FlowTitle>
      <div className="d-flex align-items-center justify-content-center flex-grow-1">
        <div className="map-image">
          <img
            className="map-pin--alex animate__animated animate__bounceIn"
            src="/map-pin.svg"
            alt="map pin"
            onClick={onMouseumPinClick}
          ></img>
          <img
            className="map-pin--museum animate__animated animate__bounceIn"
            src="/map-pin.svg"
            alt="map pin"
            onClick={onAlexPinClick}
          ></img>
          <div className="map-label map-label-museum animate__animated animate__bounceIn">
            {t('pages.map.museum')}
          </div>
          <div className="map-label map-label-alex animate__animated animate__bounceIn">
            {t('pages.map.alex')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhereNext;
