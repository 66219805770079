import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { FLOW_ITEM_TYPES } from '../../config/constants';
import useHoverAndClick from '../../hooks/useHoverAndClick';
import useMedia from '../../hooks/useMedia';
import { getSingleVideoSrc } from '../../utils';
import MediaControlsBar from '../MediaControlsBar';

function VideoSingle({
  item,
  onNextClick,
  onPrevClick,
  showPrevButton,
  onEndClick,
  language,
  flowState,
}) {
  const [ref, isShowing] = useHoverAndClick({ timeoutDuration: 3000 });
  const stepState = flowState[item.id] || {};
  const selectedMediaIndex = stepState.selectedMediaIndex || 0;
  const videoSrc = getSingleVideoSrc(item, language, selectedMediaIndex);
  const media = item.media[selectedMediaIndex];
  const title = get(media, 'title', item.title);

  const {
    progress,
    isPlaying,
    mediaRef: videoRef,
    onSeek,
    togglePlay,
  } = useMedia({
    onEnd: onNextClick,
    src: videoSrc,
    autoPlay: true,
  });

  const style =
    media.type === FLOW_ITEM_TYPES.AUDIO
      ? {
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/headphones.png)',
        }
      : {};

  return (
    <div
      ref={ref}
      className="h-100 position-relative d-flex bg-radial-gradient animate__animated animate__fadeIn"
    >
      <video
        style={style}
        key={videoSrc}
        ref={videoRef}
        className="h-100 w-100"
      >
        <source type="video/webm" src={videoSrc} />
      </video>
      <MediaControlsBar
        togglePlay={togglePlay}
        onEndClick={onEndClick}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        showPrevButton={showPrevButton}
        isPlaying={isPlaying}
        item={{ title }}
        progress={progress}
        onSeek={onSeek}
        hide={!isShowing}
      />
    </div>
  );
}

export default VideoSingle;
