import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import items from './asmrData';
import useWindowSize from '../hooks/useWindowSize';
import useCalculateLayout from '../hooks/useCalculateLayout';

function Objects({
  onItemClick = (item, id, index) => {},
  loadPlayers = (items) => [],
}) {
  const [needsRefresh, setNeedsRefresh] = useState(0);
  const [scale, setScale] = useState(1);
  const [leftMargin, setLeftMargin] = useState(0);
  const [topMargin, setTopMargin] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const { calculateASMRLayout } = useCalculateLayout();

  useEffect(() => {
    if (isNaN(windowSize.width)) {
      return
    }
    const { scale, leftMargin, topMargin } = calculateASMRLayout({ windowSize });
    setScale(scale);
    setLeftMargin(leftMargin);
    setTopMargin(topMargin);
    setIsReady(true)
  }, [windowSize]);

  useEffect(() => {
    loadPlayers(items);
  }, []);

  const toggle = (item, id, index) => {
    onItemClick(item, id, index);
    setNeedsRefresh(needsRefresh + 1);
  };

  return (
    <div className="position-relative w-100 h-100">
      <div
        id="asmr_container"
        ref={ref}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
        }}
      >
        {isReady && items.map((item, index) => {
          const id = `asmr-${index}`;
          return (
            <div
              id={id}
              alt={item.name}
              draggable={false}
              key={index}
              onClick={() => toggle(item, id, index)}
              className={item.css}
              style={{
                width: item.width * Math.min(scale, 1),
                height: item.height * Math.min(scale, 1),
                top: topMargin + item.top * Math.min(scale, 1),
                left: leftMargin + item.left * Math.min(scale, 1),
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

Objects.propTypes = {
  onItemClick: PropTypes.func,
  loadPlayers: PropTypes.func,
};

export default Objects;
