import Axios from 'axios';

function useEmail({ baseURL }) {
  const sendEmail = async ({ route, text }) => {
    const api = Axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      crossDomain: true,
    });
    const response = await api.post('/feedback', { route, text });
    return response.data;
  };

  return sendEmail;
}

export default useEmail;
