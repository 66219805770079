import React from 'react';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import useMedia from '../../hooks/useMedia';
import FlowTitle from '../FlowTitle';
import MediaOverlay from '../MediaOverlay';

function WhoShouldTellYouVideo({ onEnd, src, onVideoSelected, title }) {
  const { mediaRef } = useMedia({ src, onEnd, autoPlay: true });

  return (
    <div className="h-100 w-100 position-relative">
      <video key={src} ref={mediaRef} loop className="w-100 h-100 bg-white">
        <source type="video/webm" src={src} />
      </video>
      <MediaOverlay title={title} onClick={() => onVideoSelected(src)} />
    </div>
  );
}

function WhoShouldTellYou(props) {
  const { t } = useTranslation();
  const { item, setStateForStep, onNextClick } = props;

  return (
    <div className="h-100 d-flex flex-column">
      <FlowTitle>{t('pages.whoShouldTellYouNext.title')}</FlowTitle>
      <div className="d-flex flex-grow-1 overflow-hidden justify-content-between">
        {item.media.map((media, i) => (
          <WhoShouldTellYouVideo
            key={media.url + i}
            onEnd={noop}
            title={media.title}
            onVideoSelected={() => {
              setStateForStep([
                {
                  stepId: 'telling',
                  state: {
                    selectedMediaIndex: i,
                  },
                },
              ]);
              onNextClick();
            }}
            src={media.url}
          />
        ))}
      </div>
    </div>
  );
}

export default WhoShouldTellYou;
