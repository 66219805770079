import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const strings = {
  'en-GB': 'language-selector-en',
  de: 'language-selector-de',
};

const imageSrcs = {
  'en-GB': 'lang-en',
  de: 'lang-de',
};

function LanguageSelector(props) {
  const { onLanguageSelect, currentLanguage, isTextDark } = props;
  const { t } = useTranslation();
  const nextLang = currentLanguage === 'en-GB' ? 'de' : 'en-GB';
  const onClick = () => onLanguageSelect(nextLang);
  return (
    <button
      onClick={onClick}
      className={cx(
        'btn p-0 m-0 language-selector shadow-none font-weight-600 d-flex align-items-center',
        {
          'language-selector--dark': isTextDark,
        }
      )}
    >
      <img
        alt={nextLang}
        src={`/${imageSrcs[nextLang]}.svg`}
        className="mr-md-2"
      />
      <span className="d-none d-md-inline">{t(strings[nextLang])}</span>
    </button>
  );
}
LanguageSelector.propTypes = {
  isTextDark: PropTypes.bool,
  onLanguageSelect: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

LanguageSelector.defaultProps = {
  isTextDark: false,
};

export default LanguageSelector;
