import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';

function HomeAboutSlide({ onBackClick }) {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-3 pb-5 px-3">
      <Logo className="img-fluid mb-3 mb-md-5" />
      <div className="home__main-card__text text-justify">
        <p>{t('pages.home.about-p-1')}</p>
        <p>{t('pages.home.about-p-2')}</p>
        <p>{t('pages.home.about-p-3')}</p>
      </div>
    </div>
  );
}

HomeAboutSlide.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};

export default HomeAboutSlide;
