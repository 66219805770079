import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function FlowSelectionMinutesNav({ minuteOptions }) {
  return (
    <nav className="flex-grow-0">
      <ul className="list-unstyled d-flex justify-content-center justify-content-md-between mb-0">
        {minuteOptions.map((minutes) => (
          <li key={minutes} className="mx-2 mx-md-4">
            <NavLink
              className="experience-page-nav-link d-flex align-items-center"
              to={`/experience/${minutes}`}
            >
              <img
                className="img-fluid experience-page-nav-link__img"
                src={`/clock-${minutes}.svg`}
              />
              <span>{minutes} min</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

FlowSelectionMinutesNav.propTypes = {
  minuteOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FlowSelectionMinutesNav;
