import images from './image64';

export default {
  objects: [
    {
      // headphones
      url: images.headphones,
      x: -259,
      y: 384,
      width: 314,
      height: 167,
    },
    {
      // shapes
      url: images.shapes,
      x: -255,
      y: 319,
      width: 47,
      height: 244,
    },
    {
      // plastic cup
      url: images.plasticCup,
      x: -200,
      y: 384,
      width: 84,
      height: 105,
    },
    {
      // egg slicer
      url: images.eggSlicer,
      x: -181,
      y: 269,
      width: 96,
      height: 54,
    },
    {
      // glass
      url: images.glass,
      x: -238,
      y: 142,
      width: 107,
      height: 97,
    },
    {
      // pen
      url: images.pen,
      x: -311,
      y: 21,
      width: 100,
      height: 175,
    },
    {
      // duck
      url: images.duck,
      x: -215,
      y: -80,
      width: 88,
      height: 69,
    },
    {
      // tea cup
      url: images.teaCup,
      x: -84,
      y: -137,
      width: 95,
      height: 78,
    },
    {
      // alarm clock
      url: images.alarmClock,
      x: 72.5,
      y: -116.5,
      width: 82,
      height: 106,
    },
    {
      // vase
      url: images.vase,
      x: 185,
      y: -127.5,
      width: 76,
      height: 109,
    },
    {
      // tuning fork
      url: images.tuningFork,
      x: 301.5,
      y: -104.5,
      width: 32,
      height: 99,
    },
    {
      // shell
      url: images.shell,
      x: 432.5,
      y: -95.5,
      width: 52,
      height: 44,
    },
    {
      // radio
      url: images.radio,
      x: 525,
      y: -115,
      width: 116,
      height: 73,
    },
    {
      // blue glove
      url: images.blueGlove,
      x: 654,
      y: -130,
      width: 199,
      height: 113,
    },
    {
      // marker
      url: images.marker,
      x: 920,
      y: -88,
      width: 93,
      height: 55,
    },
    {
      // ratchet
      url: images.ratchet,
      x: 1038,
      y: -116,
      width: 129,
      height: 98,
    },
    {
      // bowl
      url: images.bowl,
      x: 1012,
      y: -69,
      width: 100,
      height: 88,
    },
    {
      // bell
      url: images.bell,
      x: 1088,
      y: 55,
      width: 83,
      height: 80,
    },
    {
      // cubes
      url: images.cubes,
      x: 943,
      y: 66,
      width: 94,
      height: 71,
    },
    {
      // red glove
      url: images.redGlove,
      x: 1062,
      y: 164,
      width: 111,
      height: 167,
    },
    {
      // yarn
      url: images.yarn,
      x: 981,
      y: 294,
      width: 150,
      height: 148,
    },
    {
      // stones
      url: images.stones,
      x: 1134,
      y: 367,
      width: 38,
      height: 117,
    },
    {
      // notebook
      url: images.notebook,
      x: 1047,
      y: 507,
      width: 101,
      height: 151,
    },
  ],
  photoFrame: images.photoFrame,
};
