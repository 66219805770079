export function getSingleVideoSrc(item, interfaceLanguage, mediaIndex) {
  const media = item.media[mediaIndex];

  if (media.multilingual) {
    return media.url.replace('-de', `-${interfaceLanguage}`);
  }

  return media.url;
}

export function getSingleAudioSrc(item, interfaceLanguage) {
  const media = item.media[0];

  if (media.multilingual) {
    return media.url.replace('-de', `-${interfaceLanguage}`);
  }

  return media.url;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
