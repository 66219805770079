import React from 'react';
import { useTranslation } from 'react-i18next';
import FlowTitle from '../FlowTitle';
import Button from '../Button';
import useMedia from '../../hooks/useMedia';
import { getSingleVideoSrc } from '../../utils';
import MediaControlsBar from '../MediaControlsBar';

function YesNoVideo(props) {
  const {
    item,
    language,
    onNextClick,
    onEndClick,
    onPrevClick,
    showPrevButton,
  } = props;
  const { t } = useTranslation();

  const videoSrc = getSingleVideoSrc(item, language, 0);

  const {
    progress,
    isPlaying,
    mediaRef: videoRef,
    onSeek,
    togglePlay,
  } = useMedia({
    onEnd: onNextClick,
    src: videoSrc,
    autoPlay: false,
  });

  return (
    <div className="h-100 position-relative overflow-hidden">
      <video key={videoSrc} ref={videoRef} className="h-100 w-100">
        <source type="video/webm" src={videoSrc} />
      </video>
      {isPlaying && (
        <MediaControlsBar
          togglePlay={togglePlay}
          onEndClick={onEndClick}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          showPrevButton={showPrevButton}
          isPlaying={isPlaying}
          item={item}
          progress={progress}
          onSeek={onSeek}
        />
      )}
      <FlowTitle className="position-absolute" style={{ top: '0' }}>
        {t(item.title)}
      </FlowTitle>
      {!isPlaying && (
        <div className="d-flex align-items-center justify-content-center mt-5 position-absolute w-100 yes-no-video__buttons-container">
          <Button
            onClick={onNextClick}
            className="text-uppercase mr-5"
            type="danger"
          >
            {t('no')}
          </Button>
          <Button
            onClick={togglePlay}
            className="text-uppercase"
            type="success"
          >
            {t('yes')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default YesNoVideo;
