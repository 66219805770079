import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import LogoType from './LogoType';
import TimeSelectionLink from './TimeSelectionLink';

function HomeMainCard(props) {
  const { isBrowserSupported, isAboutShowing } = props;
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        'bg-semi-transparent-white',
        isBrowserSupported ? 'home__main-card' : 'home__main-card-unsupported'
      )}
      style={{ opacity: isAboutShowing ? 0 : 1, transition: 'opacity 0.3s' }}
    >
      <div className="mb-0 pb-0">
        <LogoType />
        <p className="home__main-card__title mt-2 mb-4">
          {t('pages.home.subtitle')}
        </p>
        <p className="home__main-card__text font-weight-400 mb-md-3">
          {isBrowserSupported
            ? t('pages.home.text-1')
            : t('pages.home.unsupported-browsers')}
        </p>
        {isBrowserSupported && (
          <p className="home__main-card__text font-weight-400 mb-1">
            {t('pages.home.text-2')}
          </p>
        )}
      </div>
      {isBrowserSupported && (
        <div className="mt-4">
          {/* <p className="font-weight-600 mb-4">{t('pages.home.question-time')}</p> */}
          <div className="d-flex justify-content-around">
            <TimeSelectionLink minutes={5} />
            <TimeSelectionLink minutes={10} />
            <TimeSelectionLink minutes={20} />
          </div>
        </div>
      )}
    </div>
  );
}
HomeMainCard.propTypes = {
  isBrowserSupported: PropTypes.bool.isRequired,
  isAboutShowing: PropTypes.bool.apply.isRequired,
};

export default HomeMainCard;
