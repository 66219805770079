import { FLOW_ITEM_TYPES, FLOW_MEDIA_TYPES } from '../constants';

const commentaries10MinEn = [
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Vera',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/vera-en.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Meriel',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/meriel.mp3',
  },
];

const commentaries10MinDe = [
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Cathrin',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/cathrin.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Iñigo',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/inigo.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Vera',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/vera-de.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Meriel',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/meriel.mp3',
  },
];

const commentaries20MinEn = [
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Vera',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/vera-en.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Meriel',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/meriel.mp3',
  },
];
const commentaries20MinDe = [
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Cathrin',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/cathrin.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Iñigo',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/inigo.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Vera',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/vera-de.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'speech',
    title: 'Meriel',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/meriel.mp3',
  },
];

const bgMusic10Min = [
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'note',
    title: 'Musik 1',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/schubert.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'note',
    title: 'Musik 2',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/happy.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'note',
    title: 'Musik 3',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/evil.mp3',
  },
];

const bgMusic20Min = [
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'note',
    title: 'Musik 1',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/dark.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'note',
    title: 'Musik 2',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/happy.mp3',
  },
  {
    type: FLOW_MEDIA_TYPES.AUDIO,
    icon: 'note',
    title: 'Musik 3',
    url: 'https://d1y6be8ryibzao.cloudfront.net/videos/epic.mp3',
  },
];

const config10Min = {
  type: FLOW_ITEM_TYPES.VIDEO_STADT,
  title: 'Inszenierte Stadt I ',
  media: [
    {
      type: FLOW_MEDIA_TYPES.VIDEO,
      url:
        'https://d1y6be8ryibzao.cloudfront.net/videos/inszenierte-stadt.webm',
    },
  ],
  bgMedia: {
    en: [...commentaries10MinEn, ...bgMusic10Min],
    de: [...commentaries10MinDe, ...bgMusic10Min],
  },
};

const config20Min = {
  type: FLOW_ITEM_TYPES.VIDEO_STADT,
  title: 'Inszenierte Stadt I ',
  media: [
    {
      type: FLOW_MEDIA_TYPES.VIDEO,
      url:
        'https://d1y6be8ryibzao.cloudfront.net/videos/inszenierte-stadt.webm',
    },
  ],
  bgMedia: {
    en: [...commentaries20MinEn, ...bgMusic20Min],
    de: [...commentaries20MinDe, ...bgMusic20Min],
  },
};

export default { config10Min, config20Min };
