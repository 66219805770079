import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';

function LoadingPage({
  title,
  text,
  isFadingOut,
  shouldShowNextButton,
  onNextClick,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={cx(
          'animate__animated d-flex align-items-center justify-content-center h-100 bg-radial-gradient',
          {
            animate__fadeIn: !isFadingOut,
            animate__fadeOut: isFadingOut,
          }
        )}
      >
        {title !== '' && (
          <div
            className="loading-title text-center p-5"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <div
          className="loading-text text-left p-5"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
      <div
        className="position-absolute"
        style={{
          left: '50%',
          top: '70%',
          transform: 'translate(-70%, -50%)',
        }}
      >
        {shouldShowNextButton && (
          <div className="animate__animated animate__fadeIn">
            <Button type="success" onClick={onNextClick}>
              {t('common.next')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isFadingOut: PropTypes.bool.isRequired,
  shouldShowNextButton: PropTypes.bool.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

export default LoadingPage;
