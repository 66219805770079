import React, { useState } from 'react';
import useMedia from '../../hooks/useMedia';
import { getSingleVideoSrc } from '../../utils';
import MediaControlsBar from '../MediaControlsBar';
import useCalculateLayout from '../../hooks/useCalculateLayout'

function VideoDrawing({
  item,
  onNextClick,
  onPrevClick,
  showPrevButton,
  onEndClick,
  language,
  flowState,
  drawingUrl,
}) {
  const stepState = flowState[item.id] || {};
  const selectedMediaIndex = stepState.selectedMediaIndex || 0;
  const videoSrc = getSingleVideoSrc(item, language, selectedMediaIndex);
  const title = item.media[selectedMediaIndex].title || item.title;
  const [showDrawing, setShowDrawing] = useState(false);

  const { calculateFramePositionForDrawingVideo } = useCalculateLayout();

  const {
    progress,
    isPlaying,
    mediaRef: videoRef,
    onSeek,
    togglePlay,
  } = useMedia({
    onEnd: onNextClick,
    src: videoSrc,
    autoPlay: true,
  });

  if (progress > 17) {
    if (!showDrawing) {
      setShowDrawing(true);
    }
  }

  let st = {};
  if (videoRef.current) {
    const size = videoRef.current.getBoundingClientRect();
    st = calculateFramePositionForDrawingVideo({ size })
  }

  return (
    <div className="h-100 position-relative d-flex bg-radial-gradient">
      {showDrawing && (
        <div
          style={st}
          className="position-absolute animate__animated animate__fadeIn"
        >
          <img src={drawingUrl} alt="duck" className="img-fluid"></img>
        </div>
      )}
      <video key={videoSrc} ref={videoRef} className="h-100 w-100">
        <source type="video/webm" src={videoSrc} />
      </video>

      <MediaControlsBar
        togglePlay={togglePlay}
        onEndClick={onEndClick}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        showPrevButton={showPrevButton}
        isPlaying={isPlaying}
        item={{ title }}
        progress={progress}
        onSeek={onSeek}
      />
    </div>
  );
}

export default VideoDrawing;
