import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Curtain({ position, mode }) {
  const baseClass = `curtain curtain--${position}`;
  const containerClass = `curtain-container curtain-container--${position}`;
  const offScreenClass = `curtain-offscreen curtain-offscreen--${position}`;

  return (
    <div
      className={cx(containerClass, {
        [`curtain-container--${position}--default`]: mode === 'default',
        [`curtain-container--${position}--closed`]: mode === 'closed',
        [`curtain-container--${position}--open`]: mode === 'open',
        [`curtain-container--${position}--invisible`]: mode === 'invisible',
      })}
    >
      <div className={offScreenClass} />
      <div className={baseClass} />
    </div>
  );
}

Curtain.propTypes = {
  position: PropTypes.oneOf(['left', 'left-bottom', 'right', 'right-bottom'])
    .isRequired,
  mode: PropTypes.oneOf(['default', 'open', 'closed', 'invisible']),
};

Curtain.defaultProps = {
  isFullClosed: false,
};

export default Curtain;
