import React from 'react';
import useMedia from '../../hooks/useMedia';
import MediaControlsBar from '../MediaControlsBar';
import { getSingleAudioSrc } from '../../utils';

function AudioSingle({
  item,
  onNextClick,
  onPrevClick,
  showPrevButton,
  onEndClick,
  language,
}) {
  const mediaSrc = getSingleAudioSrc(item, language);
  const { mediaRef, togglePlay, onSeek, isPlaying, progress } = useMedia({
    onEnd: onNextClick,
    src: mediaSrc,
    autoPlay: true,
  });

  return (
    <div className="h-100 position-relative d-flex bg-radial-gradient animate__animated animate__fadeIn">
      <video
        ref={mediaRef}
        className="w-100 bg-white"
        autoPlay
        key={mediaSrc}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/headphones.png)',
        }}
      >
        <source type="audio/mp3" src={mediaSrc} />
      </video>
      <MediaControlsBar
        togglePlay={togglePlay}
        onEndClick={onEndClick}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        showPrevButton={showPrevButton}
        isPlaying={isPlaying}
        item={item}
        progress={progress}
        onSeek={onSeek}
      />
    </div>
  );
}

export default AudioSingle;
